import { Button, tokens } from "@fluentui/react-components";
import { Dismiss16Regular } from "@fluentui/react-icons";
import React from "react";
import styled from "styled-components";

export const TitleWrapper = styled.div`
  padding: 4px 14px 6px 8px;
  border-radius: ${tokens.borderRadiusSmall};
  border-bottom: 1px solid ${tokens.colorNeutralStroke1};
  background: ${tokens.colorNeutralBackground1};
  color: ${tokens.colorNeutralForeground3};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;

  button {
    height: 20px;
  }
`;

export const Title = styled.p`
  color: ${tokens.colorNeutralForeground4};
  font-family: "Segoe UI";
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
`;

interface Props {
  title: string;
  action: () => void;
}

const ModalTitle: React.FC<Props> = ({ title, action }) => (
  <TitleWrapper>
    <Title>{title}</Title>
    <Button appearance="transparent" icon={<Dismiss16Regular />} size="small" onClick={action} />
  </TitleWrapper>
);

export default ModalTitle;
