import { columnWidth } from "../config/excelConfig";

async function setColumnFormatting(tableArr, table){
  let styles = JSON.parse( await OfficeRuntime.storage.getItem('instant-report-style'));
  const tableHeaderRowRange = table.getHeaderRowRange();
  const borders = [
    Excel.BorderIndex.edgeLeft,
    Excel.BorderIndex.edgeTop,
    Excel.BorderIndex.edgeRight,
    Excel.BorderIndex.edgeBottom,
  ];

  tableArr["columnFormatting"].forEach((value, index) => {
    if (tableArr["header"][index]["column"].startsWith("IS_") || tableArr["header"][index]["column"].endsWith("IK"))
      table.getDataBodyRange().getColumn(index).format.fill.color = styles["InteriorColorLookupFKField"];
    tableHeaderRowRange.getColumn(index).format.fill.color = styles["InteriorColorEdit"];
    if (tableArr["columnFormatting"][index].includes("ColumnWidth")) {
      let width = tableArr["columnFormatting"][index].split("_")[1];
      table.getHeaderRowRange().getColumn(index).format.columnWidth = columnWidth[width];
      if (Number(width) === 4) table.getHeaderRowRange().getColumn(index).format.textOrientation = 90;
    }
    if (tableArr["columnFormatting"][index].includes("AutoFit")) {
      table.getHeaderRowRange().getColumn(index).format.autofitColumns();
    }
    borders.forEach((border) => {
      table.getRange().getColumn(index).format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
      tableHeaderRowRange.getColumn(index).format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
    });

    if (tableArr["header"][index]["column"].startsWith("__")) {
      table.getRange().getColumn(index).format.fill.color = styles["InteriorColorSeparator"];
      table.getRange().getColumn(index).format.columnWidth = columnWidth["#SEP#"];
      table.getRange().getColumn(index).format.font.color = styles["InteriorColorSeparator"];
    }
  });
}

function setCellFormatting(tableArr, table) {
  tableArr["cellFormatting"].forEach((value, index) => {
    let column = table.getDataBodyRange().getColumn(index);
    switch (value) {
      case "Bold":
        column.format.font.bold = true;
        break;
      case "Center":
        column.format.horizontalAlignment = Excel.HorizontalAlignment.center;
        break;
      case "Memo":
        column.format.font.size = 8;
        column.format.font.name = "Arial Narrow";
        break;
      case "AuditInfo":
        column.format.font.size = 10;
        column.format.font.name = "Arial";
        column.format.font.color = "#00419E";
        break;
      case "Text":
        column.numberFormat = [["@"]];
        break;
      case "Date":
        column.numberFormat = [["dd.mm.yyyy"]];
        break;
      case "Disabled":
        column.format.fill.pattern = Excel.FillPattern.lightHorizontal;
        column.format.fill.patternColor = "#808080";
        column.format.font.color = "#808080";
        break;
      case "Decimals_2":
        column.numberFormat = [["# ##0.00_ ;[Color3]-# ##0.00"]];
        break;
      case "TimeStamp":
        column.numberFormat = [["dd.mm.rrrr gg:mm:ss"]];
        break;
      case "Bold_blue":
        column.format.font.bold = true;
        column.format.font.color = "#0000FF";
        break;
      case "Currency_2":
        column.numberFormat = [["# ##0.00_ ;[Color3]-# ##0.00"]];
        break;
      default:
        column.numberFormat = [["@"]];
        break;
    }
  });
}

function insertTable(worksheet, headerData) {
  const tableRange = worksheet.getRange(`C3`).getResizedRange(null, headerData.length - 1);
  const table = worksheet.tables.add(tableRange, true);
  table.style = "TableStyleLight1";
  table.getRange().format.fill.clear();
  return table;
}

async function formatTable(context, table, headerData) {
  table.showBandedRows = false;
  table.showBandedColumns = false;
  table.getHeaderRowRange().values = [headerData];
  table.getHeaderRowRange().format.rowHeight = 42;
  const tableHeaderRowRange = table.getHeaderRowRange();
  let styles = await getDefaultStyle();
  table.getDataBodyRange().format.fill.color = styles["InteriorColorFilterOrDummy"];

  tableHeaderRowRange.format.verticalAlignment = Excel.VerticalAlignment.center;
  tableHeaderRowRange.format.horizontalAlignment = Excel.HorizontalAlignment.center;
  tableHeaderRowRange.format.wrapText = true;
  tableHeaderRowRange.format.font.bold = true;
  tableHeaderRowRange.format.font.name = "Aptos Narrow";
  tableHeaderRowRange.format.font.size = 8;
  tableHeaderRowRange.format.font.color = "black";
}

export const importTableStructureIntoExcel = async (tableArr) => {
  if (tableArr.length === 0) return;
  try {
    await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      await clearFormatting(context);
      worksheet.getRange("A:B").format.columnWidth = columnWidth[3];
      let headerData = tableArr["header"].map((value) => value.ep);
      let table = insertTable(worksheet, headerData);
      await formatTable(context, table, headerData);
      await setColumnFormatting(tableArr, table);
      setCellFormatting(tableArr, table);

      return context
        .sync()
        .then(() => console.log("Values added successfully!"))
        .catch((err) => {
          console.error("Error: " + JSON.stringify(err));
        });
    });
    console.log("Load Table Clicked");
  } catch (error) {
    console.log("Error: " + error);
  }
};


export const importDataIntoTable = async (tableArr) => {
  if (tableArr.length === 0) return;
  try {
    await Excel.run(async (context) => {
      const worksheet = context.workbook.worksheets.getActiveWorksheet();
      await clearFormatting(context);
      worksheet.getRange("A:B").format.columnWidth = columnWidth[3];
      worksheet.freezePanes.freezeRows(3);
      worksheet.freezePanes.freezeColumns(3);
      console.log(tableArr);
      let headerData = tableArr["header"].map((value) => value.ep);
      let table = insertTable(worksheet, headerData);
      let bodyData = tableArr["data"].map((obj) => Object.values(obj));

      table.rows.add(null, bodyData);
      table.showBandedRows = false;
      table.showBandedColumns = false;
      table.getHeaderRowRange().values = [headerData];
      table.getHeaderRowRange().format.rowHeight = 42;

      table.getDataBodyRange().format.fill.color = "white";
      const tableHeaderRowRange = table.getHeaderRowRange();
      tableHeaderRowRange.format.verticalAlignment = Excel.VerticalAlignment.center;
      tableHeaderRowRange.format.horizontalAlignment = Excel.HorizontalAlignment.center;
      tableHeaderRowRange.format.wrapText = true;
      tableHeaderRowRange.format.font.bold = true;
      tableHeaderRowRange.format.font.name = "Aptos Narrow";
      tableHeaderRowRange.format.font.size = 8;
      tableHeaderRowRange.format.font.color = "black";

      await setColumnFormatting(tableArr, table);
      setCellFormatting(tableArr, table);

      table.getDataBodyRange().format.wrapText = true;
      table.getDataBodyRange().format.rowHeight = 15;

      for (let i = 1; i < tableArr["data"].length; i++) {
        let row = table.getDataBodyRange().getRow(i);
        row.format.borders.getItem(Excel.BorderIndex.edgeTop).style = Excel.BorderLineStyle.dot;
        row.format.borders.getItem(Excel.BorderIndex.edgeTop).color = "#000000";
      }

      return context
        .sync()
        .then(() => console.log("Values added successfully!"))
        .catch((err) => {
          console.error("Error: " + JSON.stringify(err));
        });
    });
    console.log("Load Table Clicked");
  } catch (error) {
    console.log("Error: " + error);
  }
};

