import styled from "styled-components";
import { tokens } from "@fluentui/react-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 90px;

  &.wrapper_non-validation {
    height: 70px;
  }

  &.wrapper_non-validation {
    height: 70px;
  }

  &.wrapper_para-Checkbox {
    height: 55px;
  }

  &.wrapper_standard-Checkbox {
    height: 30px;
    transform: translateX(-6px);
  }

  .fui-Listbox {
    max-width: 280px !important;
  }

  .fui-Field {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    justify-items: start;
    row-gap: 2px;
  }

  .fui-Input,
  .fui-Combobox {
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: 10px;
  }

  label {
    font-weight: 500;
    margin-bottom: 1px;
  }

  input {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DropdownWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  top: 2px;
  left: 2px;
  right: 2px;
  background-color: ${tokens.colorNeutralBackground1};
  min-width: 128px;
  height: 100vh;
  border-radius: ${tokens.borderRadiusSmall};
  box-shadow: ${tokens.shadow16};
  display: none;

  &.active {
    display: grid;
    grid-template-rows: max-content max-content 1fr;
  }
`;

export const InputSection = styled.div`
  padding: 6px 8px 16px;
  border-bottom: 1px solid ${tokens.colorNeutralStroke1};
`;

export const InputWrapper = styled.div`
  border: 1px solid ${tokens.colorNeutralStroke1};
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 12px;
  align-items: center;
  padding: 4px 8px;
  border-radius: ${tokens.borderRadiusMedium};
  box-sizing: border-box;

  &.active {
    border: 1px solid ${tokens.colorBrandStroke2Hover};
  }
`;

export const CustomizeInput = styled.input`
  border: none;
  outline: none;
  appearance: none;
  font-family: "Segoe UI";
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
  width: 100%; /* Ensure full width inside the wrapper */
  color: ${tokens.colorNeutralForeground1};
  box-sizing: border-box;
`;

export const TagGroupWrapper = styled.div`
  margin: 6px 0 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

export const Tag = styled.div`
  border: 1px solid ${tokens.colorNeutralStroke2};
  border-radius: ${tokens.borderRadiusMedium};
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: ${tokens.colorNeutralBackground3};
  padding: 2px 2px 2px 8px;

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: ${tokens.colorNeutralForeground4};
    transform: translateY(-1px);
  }

  button {
    height: 14px;
  }
`;

export const DropdownList = styled.div`
  padding: 12px 4px;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  overflow-y: auto;
  margin-bottom: 12px;
  /* max-height: 690px; */

  /* Apply thin scrollbars across WebKit browsers */
  &::-webkit-scrollbar {
    width: 6px; /* Thin width for the scrollbar */
  }

  /* Track (background of scrollbar) */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for track */
    border-radius: 10px;
  }

  /* Handle (the actual scrolling part) */
  &::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the handle */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }
`;

export const DropdownListItem = styled.div`
  cursor: pointer;
  padding: 6px 8px;
  border-radius: ${tokens.borderRadiusMedium};
  color: ${tokens.colorNeutralForeground2};
  background-color: ${tokens.colorNeutralBackground1};
  font-size: ${tokens.fontSizeBase300};

  &.hovered {
    background-color: ${tokens.colorNeutralBackground4};
  }

  &.selected {
    background-color: ${tokens.colorBrandBackground2Hover};
  }

  p:first-of-type {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 11px;
    font-weight: 500;
  }
`;

export const Preview = styled.div`
  position: fixed;
  padding: 4px 6px;
  left: 4rem;
  bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: ${tokens.shadow16};
  background-color: ${tokens.colorNeutralBackground1};
  z-index: 99999;
`;
