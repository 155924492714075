import React, { useContext, useState } from "react";
import { Button, Link, tokens } from "@fluentui/react-components";
import styled from "styled-components";
import Header from "./Header";
import Message from "./Message";
import { login } from "../utils/apiCalls";
import { ClientContext } from "./App";
import { ClientInfoContext } from "../models/client";
import { messageList } from "../utils/messageList";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;
`;

export const Inner = styled.div`
  padding: 40px 20px 50px;
  display: grid;
  grid-template-rows: 1fr max-content;
  justify-content: center;

  &.error {
    align-items: start;
    justify-self: center;
    max-width: 460px;
  }
`;

export const MainContent = styled.div`
  align-self: center;
  width: min-content;
  /* transform: translateY(-60px); */
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.div`
  margin-bottom: 18px;
  min-width: 280px;
  text-align: center;

  h1 {
    font-family: "Segoe UI";
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 12px;

    span {
      white-space: nowrap;
    }
  }

  p {
    color: ${tokens.colorNeutralForeground3};
    font-size: 14px;
    line-height: 20px;
  }
`;

export const BtnLink = styled.div`
  width: 100%;
  display: grid;
  text-align: center;

  button {
    margin-bottom: 10px;
  }

  p {
    color: ${tokens.colorNeutralForeground2};
    font-size: 14px;
    line-height: 20px;
  }

  .contact-link {
    margin: 0 3px 0 6px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateX(-10px);

  p {
    color: ${tokens.colorNeutralForeground4};
    font-size: 10px;
    line-height: 14px;
  }
`;

interface Props {
  setAuth: React.Dispatch<React.SetStateAction<boolean>>;
}

const Initial: React.FC<Props> = ({ setAuth }) => {
  const clientInfo: ClientInfoContext = useContext(ClientContext);

  const [loginErr, setLoginErr] = useState<boolean>(false);

  const handleAuthCheckClick = async () => {
    try {
      const isAuth = await login();

      if (isAuth) {
        setAuth(true);
        setLoginErr(false);
      } else {
        setAuth(false);
        setLoginErr(true);
      }
    } catch (err) {
      console.log("Err: ", err);
      setAuth(false);
      setLoginErr(true);
    }
  };

  const handleBackClick = () => {
    setLoginErr(false);
  };

  return (
    <Wrapper>
      <Header clientLogo={clientInfo.clientLogo} productLogo={clientInfo.productLogo} />

      <Inner className={loginErr ? "error" : ""}>
        {loginErr ? (
          <ErrorContent>
            <Message type="error" title="Login Failed" desc={messageList.authErr} isMainBtn={false} />

            <BtnLink>
              <Button appearance="secondary" size="medium" as="button" onClick={handleBackClick}>
                Go Back
              </Button>
              <p>
                New user?
                <Link className="contact-link" href={`mailto:${clientInfo.contact}`}>
                  Contact us
                </Link>
                to get started today!
              </p>
            </BtnLink>
          </ErrorContent>
        ) : (
          <MainContent>
            <Title>
              <h1>
                Welcome to <span>{clientInfo.title}</span>!
              </h1>
              <p>The most efficient Excel integrated enterprise grade cloud database.</p>
            </Title>
            <BtnLink>
              <Button appearance="primary" size="medium" as="button" onClick={handleAuthCheckClick}>
                Start
              </Button>
              <p>
                New user?
                <Link className="contact-link" href={`mailto:${clientInfo.contact}`}>
                  Contact us
                </Link>
                to get started today!
              </p>
            </BtnLink>
          </MainContent>
        )}

        <Info>
          <p>Version 0.0.1</p>
        </Info>
      </Inner>
    </Wrapper>
  );
};

export default Initial;
