import React from "react";
import PropTypes from "prop-types";
import { Image } from "@fluentui/react-components";
import styled from "styled-components";
import { ClientInfoContext } from "../models/client";

const Wrapper = styled.div`
  position: relative;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;

  .logo {
    position: absolute;
    top: 10px;
    left: 12px;
  }
`;

const Header: React.FC<ClientInfoContext> = ({ clientLogo, productLogo }) => {
  return (
    <Wrapper>
      {clientLogo && <Image className="logo" width="20" src={clientLogo} alt="logo" />}
      {productLogo && <Image className="title" height="16" src={productLogo} alt="product-logo" />}
    </Wrapper>
  );
};

Header.propTypes = {
  clientLogo: PropTypes.string,
  productLogo: PropTypes.string,
};

export default Header;
