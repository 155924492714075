/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Checkbox, Field } from "@fluentui/react-components";

export const Wrapper = styled.div`
  margin-bottom: 6px;
`;

interface Props {
  className: string;
  label: string;
  isChecked: boolean;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChange: () => void;
}

const NewCheckbox: React.FC<Props> = ({ className, label, isChecked, handleKeyDown, handleChange }) => {
  return (
    <Wrapper>
      <Field>
        <Checkbox
          className={className}
          label={label}
          checked={isChecked}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
        />
      </Field>
    </Wrapper>
  );
};

export default NewCheckbox;
