import React, { useRef, useEffect, useState } from "react";
import { Button, Field, Input } from "@fluentui/react-components";
import { Dismiss12Regular, ChevronDown16Regular } from "@fluentui/react-icons";
import {
  Wrapper,
  DropdownWrapper,
  InputSection,
  InputWrapper,
  CustomizeInput,
  DropdownList,
  DropdownListItem,
  FieldWrapper,
} from "./common.styles";
import ModalTitle from "../Modals/ModalTitle";
import { optionItemMouseEnter, optionItemMouseLeave } from "../../helpers/mouseEvent";
import { SelectPara } from "../../models/instantReport";

interface Props {
  paraName: string;
  label: string;
  valueType: string;
  defaultValue: string;
  isMandatory: boolean;
  size: number;
  optionListKeys: string[] | [];
  optionList: any[];
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const ParaSelect: React.FC<Props> = ({
  paraName,
  label,
  valueType,
  defaultValue,
  isMandatory,
  size,
  optionListKeys,
  optionList,
  selectedPara,
  setSelectedPara,
}) => {
  const paraMainKey = optionListKeys[0];
  const targetSelectedParaObj = selectedPara[paraName];

  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [activeOptionIdx, setActiveOptionIdx] = useState<number>(-1);
  const [selectedOptionIdx, setSelectedOptionIdx] = useState<number>(-1);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOptionList, setFilteredOptionList] = useState<any[]>([]);

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        value: defaultValue || "",
        isMandatory: isMandatory,
        type: valueType,
        err: null,
      },
    }));

    setSelectedOption(defaultValue || "");

    let sortedOptionList;
    // { "": ["Last 24 hours", ""] }
    if (Array.isArray(optionList[0][paraMainKey])) {
      sortedOptionList = [...optionList];
    } else {
      // { USERACCESSTABLE: "", DESCRIPTION: null }
      sortedOptionList = optionList
        .sort((a, b) => String(a[paraMainKey]).localeCompare(String(b[paraMainKey])))
        .map((option) => {
          for (const key of optionListKeys) {
            if (typeof option[key] === "number") {
              option[key] = option[key].toString();
            }
            return option;
          }
        });
    }

    setFilteredOptionList(sortedOptionList);
  }, []);

  // Update filtered options when searchValue changes
  useEffect(() => {
    const filteredList = optionList?.filter((option) => {
      // { "": ["Last 24 hours", ""] }
      if (Array.isArray(optionList[0][paraMainKey])) {
        return option[paraMainKey][0].toLowerCase().includes(searchValue.toLowerCase());
      } else {
        // { USERACCESSTABLE: "", DESCRIPTION: null }
        {
          for (const key of optionListKeys) {
            return option[key].toLowerCase().includes(searchValue.toLowerCase());
          }
        }
      }
    });
    setFilteredOptionList(filteredList);
  }, [searchValue, optionList]);

  // Scroll into view logic when activeOptionIdx changes
  useEffect(() => {
    if (activeOptionIdx >= 0 && optionRefs?.current[activeOptionIdx]) {
      optionRefs.current[activeOptionIdx]?.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeOptionIdx]);

  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
      searchInputRef.current?.focus();
      document.getElementById(`paraSelect_${paraName}`)?.classList.add("active");

      // Set activeOptionIdx to the index of the selected option (if available)
      const selectedIdx = optionList?.findIndex((option) => {
        // { "": ["Last 24 hours", ""] }
        if (Array.isArray(option[paraMainKey])) {
          return option[paraMainKey][0] === selectedOption;
        } else {
          // { USERACCESSTABLE: "", DESCRIPTION: null }
          return option[paraMainKey] === selectedOption;
        }
      });

      setActiveOptionIdx(selectedIdx >= 0 ? selectedIdx : 0);
      setSelectedOptionIdx(selectedIdx);

      // Use setTimeout to defer the scrollIntoView until after rendering completes
      const timeoutId = setTimeout(() => {
        if (selectedIdx >= 0 && optionRefs?.current[selectedIdx]) {
          optionRefs.current[selectedIdx]?.scrollIntoView({
            block: "start",
            inline: "nearest",
          });
        }
      }, 0);

      // Cleanup function to clear the timeout
      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setSearchValue(selectedOption || "");
      return;
    }
  }, [isOpen, optionList]);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
    if (!isOpen) {
      inputRef.current?.focus();
      document.getElementById(`paraSelect_${paraName}`)?.classList.remove("active");
    }
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    setOpen(true);
    setActiveOptionIdx(0);
  };

  const handleSelect = (targetValue: string, targetIdx: number) => {
    setSelectedOption(targetValue);
    setSearchValue("");
    setActiveOptionIdx(targetIdx);
    setSelectedOptionIdx(targetIdx);
    setFilteredOptionList([...optionList]);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: targetValue, err: null },
    }));

    setOpen(false);
    inputRef.current?.focus();
    document.getElementById(`paraSelect_${paraName}`)?.classList.remove("active");
  };

  const handleClearClick = () => {
    setSelectedOption("");
    setSearchValue("");
    setActiveOptionIdx(-1);
    setSelectedOptionIdx(-1);
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: "", err: null },
    }));
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Enter":
        e.preventDefault();
        setOpen(true);
        break;
      case "Escape":
        e.preventDefault();
        handleClearClick();
        break;
      default:
        break;
    }
  };

  // Handle key navigation (Up, Down, Tab, Enter, Escape)
  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          // Handle Shift + Tab (move backwards = ArrowUp)
          setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        } else {
          // Handle Tab (move forward = ArrowDown)
          setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (activeOptionIdx >= 0) {
          if (Array.isArray(filteredOptionList[activeOptionIdx][paraMainKey])) {
            handleSelect(filteredOptionList[activeOptionIdx][paraMainKey][0], activeOptionIdx);
          } else {
            handleSelect(filteredOptionList[activeOptionIdx][paraMainKey], activeOptionIdx);
          }
        }

        break;
      case "Escape":
        e.preventDefault();
        if (searchValue.length > 0) {
          handleClearClick();
        } else {
          setOpen(false);
          inputRef.current?.focus();
          document.getElementById(`paraSelect_${paraName}`)?.classList.remove("active");
        }
        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <FieldWrapper>
        <Field
          label={label}
          size="medium"
          onKeyDown={handleInputKeyDown}
          required={isMandatory}
          validationMessage={
            targetSelectedParaObj?.err === "empty"
              ? "This field cannot be empty"
              : targetSelectedParaObj?.err === "exceed"
              ? `The maximum character limit for this field is ${size}. The number of characters entered is ${
                  typeof targetSelectedParaObj?.value === "string" ? targetSelectedParaObj?.value.length : null
                }`
              : null
          }
        >
          <Input
            ref={inputRef}
            className="input-dropdown"
            size="medium"
            placeholder="..."
            value={selectedOption || ""}
            onChange={() => {
              setOpen(true);
            }}
            onClick={toggleDropdown}
            contentAfter={<ChevronDown16Regular />}
          />
        </Field>
      </FieldWrapper>

      <DropdownWrapper className={isOpen ? "active" : ""} onKeyDown={handleKeyDown}>
        <ModalTitle title={label} action={() => setOpen(false)} />

        <InputSection>
          <InputWrapper id={`paraSelect_${paraName}`}>
            <CustomizeInput ref={searchInputRef} placeholder="..." value={searchValue} onChange={handleInputChange} />
            <Button appearance="transparent" icon={<Dismiss12Regular />} size="small" onClick={handleClearClick} />
          </InputWrapper>
        </InputSection>

        <DropdownList role="listbox">
          {filteredOptionList.length > 0 &&
            filteredOptionList.map((valueItem, idx) => {
              if (Array.isArray(valueItem[paraMainKey])) {
                return (
                  <DropdownListItem
                    key={`select_${paraName}_option-${valueItem[paraMainKey][0]}-${idx}`}
                    ref={(el) => (optionRefs.current[idx] = el)}
                    className={`dropdown-item ${
                      idx === selectedOptionIdx ? "selected" : idx === activeOptionIdx ? "hovered" : ""
                    }`}
                    tabIndex={0}
                    onClick={() => handleSelect(valueItem[paraMainKey][0], idx)}
                    onMouseEnter={() => setActiveOptionIdx(idx)}
                  >
                    {valueItem[paraMainKey].map((arrayValue: string, idx: number) => (
                      <p key={`option_${valueItem[paraMainKey][0]}_${arrayValue}-${idx}`}>{arrayValue}</p>
                    ))}
                  </DropdownListItem>
                );
              } else {
                return (
                  <DropdownListItem
                    key={`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`}
                    id={`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`}
                    ref={(el) => (optionRefs.current[idx] = el)}
                    className={`dropdown-item ${
                      idx === selectedOptionIdx ? "selected" : idx === activeOptionIdx ? "hovered" : ""
                    }`}
                    tabIndex={0}
                    role="option"
                    aria-selected={idx === selectedOptionIdx}
                    onClick={() => handleSelect(valueItem[paraMainKey], idx)}
                    onMouseEnter={() =>
                      optionItemMouseEnter(`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`)
                    }
                    onMouseLeave={() =>
                      optionItemMouseLeave(`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`)
                    }
                  >
                    {optionListKeys.map((paraKey, idx) => (
                      <p key={`option_${valueItem[paraMainKey]}_${valueItem[paraKey]}-${idx}`}>{valueItem[paraKey]}</p>
                    ))}
                  </DropdownListItem>
                );
              }
            })}

          {filteredOptionList.length === 0 && (
            // <DropdownListItem>New value: {searchValue}</DropdownListItem>
            <DropdownListItem>No item found</DropdownListItem>
          )}
        </DropdownList>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default ParaSelect;
