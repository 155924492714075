import {columnWidth as columnWidths, defaultFormattingOptions, styles} from "../config/excelConfig";
import {getImage, getReportStyle} from "../utils/apiCalls";

function bufferToBase64(uint8Array) {
    let binaryString = "";
    for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
    }
    return btoa(binaryString);
}


async function getImageBase64ById(imageId, imageCache) {
    if (imageCache.has(imageId)) {
        return imageCache.get(imageId);
    }

    const images = await getImage(imageId);
    if (!Array.isArray(images) || images.length === 0 || !images[0].IMAGEFILE || !images[0].IMAGEFILE.data) {
        throw new Error("Invalid image data!");
    }
    const bufferData = images[0].IMAGEFILE.data;
    if (!Array.isArray(bufferData)) {
        throw new Error("Image data is not in expected format!");
    }
    const uint8Array = new Uint8Array(bufferData);
    const base64Image = bufferToBase64(uint8Array);

    imageCache.set(imageId, base64Image);
    return base64Image;
}

async function processImages(sheet, images, scaleFactor = 1) {
    let imagesArray = images?.imagesArray;
    if (!imagesArray) return;

    const imageCache = new Map();

    for (const image of imagesArray) {
        const base64Image = await getImageBase64ById(image.imageKey, imageCache);
        const imageShape = sheet.shapes.addImage(base64Image);
        if (image.scaleFactor) scaleFactor = image.scaleFactor;

        const cell = sheet.getRangeByIndexes(image.row + 3, image.column + 1, 1, 1);
        cell.load(["left", "top", "width", "height"]);

        await sheet.context.sync();

        imageShape.left = cell.left;
        imageShape.top = cell.top;

        imageShape.load(["width", "height"]);
        await sheet.context.sync();

        imageShape.width *= Number(scaleFactor);
        imageShape.height *= Number(scaleFactor);
    }

    await sheet.context.sync();
}


function mergeRanges(dataRange, merges) {
    if (!merges) return;
    if (!merges || !Array.isArray(merges.mergesArray)) {
        console.error("Error - Invalid merge configuration!");
        throw new Error("Invalid merge configuration!");
    }

    for (let i = 0; i < merges.mergesArray.length; i++) {
        let { rowStart, columnStart, height, width } = merges.mergesArray[i];
        let mergeRange = dataRange
            .getCell(rowStart-2, columnStart)
            .getResizedRange(height - 1, width - 1);

        mergeRange.merge();
    }
}

async function removeAllImages(sheet, context) {
    let shapesArr = sheet.shapes;
    shapesArr.load("items");
    await context.sync();
    shapesArr.items.forEach(shape => shape.delete());
}


function parseVBAHEX(vbaHexString) {
    if (typeof vbaHexString !== "string") return;
    let modified = vbaHexString.substring(2);
    return "#" + modified.substring(4, 6) + modified.substring(2, 4) + modified.substring(0, 2);
}

async function getDefaultStyle() {
    let resp = await getReportStyle();
    let mappedResp = {};
    for (let i = 0; i < resp[0].length; i++) {
        mappedResp[resp[0][i]["PropertyName"]] = parseVBAHEX(resp[0][i]["StrPropertyValue"]);
    }
    return mappedResp;
}


function rgbToHex(result) {
    if (result) {
        const red = result.r;
        const green = result.g;
        const blue = result.b;

        return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).toUpperCase()}`;
    }
    return null;
}

function clearSheet(sheet) {
    sheet.getRange().clear(Excel.ClearApplyTo.all);
    sheet.getRange().format.rowHeight = 15;
    sheet.getRange().format.columnWidth = 47.83;
    sheet.freezePanes.unfreeze();
    sheet.showGridlines = false;
    sheet.getRange("A1").format.columnWidth = columnWidths[3];
}


function getData(report, epKey) {
    const skipEpColumns = ["TITLE", "DESC", "INFO", "PARA", "COLSIZE", "FORMAT", "HEADER", "HEADERNOFILTER", "IMAGES", "MERGES"];
    let columnFormatting = [];
    let rowFormatting = [];
    let images = [];
    let merges = [];

    const data = report
        .filter(item => {
            const epValue = item[epKey];
            if (epValue === null || epValue === "" || epValue === " ") rowFormatting.push(epValue);
            let parsedEpValue;
            try {
                parsedEpValue = JSON.parse(epValue);
            } catch (e) {
                return true;
            }
            if (parsedEpValue === null) return true;

            if (skipEpColumns.every(item => item !== parsedEpValue?.defaultFormatting))
                rowFormatting.push(parsedEpValue);

            if (parsedEpValue.defaultFormatting?.toString().toUpperCase() === "IMAGES") {
                images.push(parsedEpValue);
            }

            if (parsedEpValue.defaultFormatting?.toString().toUpperCase() === "MERGES") {
                merges.push(parsedEpValue);
            }


            if (parsedEpValue && parsedEpValue.defaultFormatting?.toString().toUpperCase() === "FORMAT") {
                try {
                    const keys = Object.keys(item);
                    for(let key of keys){
                        let parsedColumnFormat = JSON.parse(item[key]);
                        if (parsedColumnFormat.defaultFormatting === "FORMAT") continue;
                        columnFormatting.push(parsedColumnFormat);
                    }
                } catch (e) {
                    console.log(`Couldn't parse ${item}`);
                }
            }

            return !skipEpColumns.includes(parsedEpValue.defaultFormatting);
        })
        .map(({ [epKey]: _, ...rest }) => Object.values(rest));

    return {
        data,
        columnFormatting,
        rowFormatting,
        images,
        merges
    };
}

function getMetaData(report, epKey) {
    const validFormats = ["TITLE", "DESC", "INFO", "PARA"];
    const metaDataFormatting = [];
    const metaDataValues = [];
    report.filter(item => {
        try {
            const parsedItem = JSON.parse(item[epKey]);
            if (validFormats.includes(parsedItem?.defaultFormatting?.toUpperCase())){
                metaDataFormatting.push(parsedItem);
                const itemWithoutEpKey = Object.keys(item).reduce((acc, key) => {
                    if (key !== epKey) acc[key] = item[key];
                    return Object.values(acc);
                }, {});

                metaDataValues.push(itemWithoutEpKey);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    });
    return {metaDataFormatting: metaDataFormatting, metaDataValues: metaDataValues};
}

function getHeaderData(report, epKey) {
    const result = report.find(item => {
        try {
            const parsedItem = JSON.parse(item[epKey]);
            return parsedItem?.defaultFormatting.toUpperCase() === 'HEADER' || parsedItem?.defaultFormatting.toUpperCase() === 'HEADERNOFILTER';
        } catch (e) {
            return false;
        }
    });

    if (result !== undefined) {
        const headerData = Object.keys(result)
            .filter(key => key !== epKey)
            .map(key => result[key]);

        const headerFormatting = JSON.parse(result[epKey]);

        return {
            headerData,
            headerFormatting
        };
    }

    return {
        headerData: [],
        headerFormatting: null
    };
}

function hexToRgb(hex) {
    hex = hex.replace('#', '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return { r, g, b };
}
function darkenColor(hexColor) {
    let rgbColor = hexToRgb(hexColor);

    rgbColor.r = Math.round(Math.pow(Math.pow(rgbColor.r, 2.2) * 0.85, 1 / 2.2));
    rgbColor.g = Math.round(Math.pow(Math.pow(rgbColor.g, 2.2) * 0.85, 1 / 2.2));
    rgbColor.b = Math.round(Math.pow(Math.pow(rgbColor.b, 2.2) * 0.85, 1 / 2.2));
    return rgbToHex(rgbColor);
}


function setFormatting(range, parsedValue) {
    const defaultFormat = defaultFormattingOptions[parsedValue?.defaultFormatting?.toUpperCase()];
    const customFormat = parsedValue?.customFormatting;

    if (defaultFormat) {
        if (defaultFormat?.format) {
            if (defaultFormat.format?.fill?.color) range.format.fill.color = defaultFormat.format.fill.color;
            if (defaultFormat.format?.rowHeight) range.format.rowHeight = defaultFormat.format.rowHeight;
            if (defaultFormat.format?.columnWidth) range.format.columnWidth = defaultFormat.format.columnWidth;

            if (defaultFormat.format?.font) {
                if (defaultFormat.format.font.size) range.format.font.size = defaultFormat.format.font.size;
                if (defaultFormat.format.font.name) range.format.font.name = defaultFormat.format.font.name;
                if (defaultFormat.format.font.bold) range.format.font.bold = defaultFormat.format.font.bold;
                if (defaultFormat.format.font.italic) range.format.font.italic = defaultFormat.format.font.italic;
                if (defaultFormat.format.font.underline) range.format.font.underline = defaultFormat.format.font.underline;
                if (defaultFormat.format.font.color) range.format.font.color = styles[defaultFormat.format.font.color];
            }
        }
        if (defaultFormat?.format?.columnWidth) range.format.columnWidth = columnWidths[defaultFormat.columnWidth];

        if (defaultFormat?.color) {
            if (styles[defaultFormat?.color] !== null && styles[defaultFormat?.color] !== undefined)
                range.format.fill.color = styles[defaultFormat?.color];
        }
        if (defaultFormat?.content === 'clear') {
            range.clear(Excel.ClearApplyTo.contents);
            range.format.borders.getItem(Excel.BorderIndex.insideVertical).style = Excel.BorderLineStyle.none;
            range.format.borders.getItem(Excel.BorderIndex.insideHorizontal).style = Excel.BorderLineStyle.none;
        }
        if (defaultFormat?.format?.numberFormat) range.format.numberFormat = [[defaultFormat.format.numberFormat]];
    }

    if (customFormat?.format?.fill) {
        if (customFormat?.format?.fill?.color) range.format.fill.color = customFormat.format.fill.color;
        if (customFormat?.format?.fill?.pattern) range.format.fill.pattern = customFormat.format.fill.pattern;
        if (customFormat?.format?.fill?.patternColor) range.format.fill.patternColor = customFormat.format.fill.patternColor;
        if (customFormat?.format?.fill?.patternTintAndShade) range.format.fill.patternTintAndShade = customFormat.format.fill.patternTintAndShade;
    }
}

function setCustomFormatting(range, parsedValue) {
    const customFormat = parsedValue?.customFormatting;
    if (!customFormat) return;
    if (customFormat?.numberFormat !== null && customFormat?.numberFormat !== undefined) range.numberFormat = [[customFormat.numberFormat]];
    if (customFormat.format) {
        if (customFormat.format?.rowHeight) range.format.rowHeight = customFormat.format.rowHeight;
        if (customFormat.format?.wrapText !== undefined) {
            range.format.wrapText = customFormat.format.wrapText;
        }
        if (customFormat.format?.shrinkToFit) range.format.shrinkToFit = customFormat.format.shrinkToFit;
        if (customFormat.format?.textOrientation) range.format.textOrientation = customFormat.format.textOrientation;
        if (customFormat.format?.horizontalAlignment) range.format.horizontalAlignment = customFormat.format.horizontalAlignment;
        if (customFormat.format?.verticalAlignment) range.format.verticalAlignment = customFormat.format.verticalAlignment;
        if (customFormat.format?.font){
            if (customFormat.format.font.size) range.format.font.size = customFormat.format.font.size;
            if (customFormat.format.font.name) range.format.font.name = customFormat.format.font.name;
            if (customFormat.format.font.bold) range.format.font.bold = customFormat.format.font.bold;
            if (customFormat.format.font.italic) range.format.font.italic = customFormat.format.font.italic;
            if (customFormat.format.font.underline) range.format.font.underline = customFormat.format.font.underline;
            if (customFormat.format.font.color) range.format.font.color = customFormat.format.font.color;
            if (customFormat.format.font.name) range.format.font.name = customFormat.format.font.name;
            if (customFormat.format.font.tintAndShade) range.format.font.tintAndShade = customFormat.format.font.tintAndShade;
            if (customFormat.format.font.superscript) range.format.font.superscript = customFormat.format.font.superscript;
            if (customFormat.format.font.strikethrough) range.format.font.strikethrough = customFormat.format.font.strikethrough;
        }
        if (customFormat.format?.columnWidth) {
            range.format.columnWidth = columnWidths[customFormat.format.columnWidth];
        }
        if (customFormat.format?.indentLevel) {
            range.format.horizontalAlignment = Excel.HorizontalAlignment.left;
            range.format.indentLevel = customFormat.format.indentLevel;
        }
    }
    if (customFormat?.fillOverlap) {
        if (customFormat.fillOverlap?.color) range.format.fill.color = customFormat.fillOverlap.color;
        if (customFormat.fillOverlap?.pattern) range.format.fill.pattern = customFormat.fillOverlap.pattern;
        if (customFormat.fillOverlap?.patternColor) range.format.fill.patternColor = customFormat.fillOverlap.patternColor;
        if (customFormat.fillOverlap?.patternTintAndShade) range.format.fill.patternTintAndShade = customFormat.fillOverlap.patternTintAndShade;
    }
}

function setMetaData(sheet, metaDataValues, metaDataFormatting) {
    let titleRange = sheet.getRange("B1").getResizedRange(0, metaDataValues[0].length-1);
    titleRange.values = [metaDataValues[0]];
    setFormatting(titleRange, metaDataFormatting[0]);
    for (let i = 1; i < metaDataFormatting.length; i++) {
        let range = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, metaDataValues[0].length-1).getLastRow();
        if (metaDataFormatting[i] === null || metaDataFormatting[i] === "" || metaDataFormatting[i] === " ") continue;
        setFormatting(range, metaDataFormatting[i]);
        range.values = [metaDataValues[i]];
    }
}

function setHeaderValues(headerRange, headerData) {
    try {
        headerRange.values = [headerData];
    } catch (e) {
        console.log(`Couldn't insert header values!`);
        return e;
    }
}

function setHeaderFormatting(headerRange, headerFormatting) {
    const defaultFormat = defaultFormattingOptions[headerFormatting?.defaultFormatting];

    try {
        headerRange.format.set(defaultFormat.format);
    } catch (e) {
        console.log(e);
        if (e?.debugInfo?.errorLocation === "RangeFormat.set")
            console.log(e.debugInfo)
    }
    if (defaultFormat?.color) headerRange.format.fill.color = styles[defaultFormat.color];

    const allDefaultRowBorders = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
    for (const border of allDefaultRowBorders) {
        headerRange.format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
        headerRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
    }


}
function setHeader(headerRange, headerData, headerFormatting){
    setHeaderValues(headerRange, headerData);
    setHeaderFormatting(headerRange, headerFormatting);
}

function setDefaultRowFormatting(dataRange) {
    const defaultRowFormat = {format:{
            rowHeight: 20,
            verticalAlignment: Excel.VerticalAlignment.center,
            horizontalAlignment: Excel.HorizontalAlignment.center,
            wrapText: true,
            font: {size: 8},
            fill: {color: styles['InteriorColorMainSheet']},
        }};
    try {
        dataRange.format.set(defaultRowFormat.format);
    } catch (e) {
        console.log(e);
        if (e?.debugInfo?.errorLocation === "RangeFormat.set")
            console.log(e.debugInfo.innerError)
    }
    const allDefaultRowBorders = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
    for (const border of allDefaultRowBorders) {
        dataRange.format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
        dataRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
    }
}


function setFirstBodyFormatting(dataRange, rowFormatting, data) {
    dataRange.values = data;
    setDefaultRowFormatting(dataRange);
    setFirstRowFormatting(dataRange, rowFormatting);
}


function setBorder(row, borderIndex, borderStyle, borderColor) {
    if (borderStyle) row.format.borders.getItem(borderIndex).style = borderStyle;
    if (borderColor) row.format.borders.getItem(borderIndex).color = borderColor;
}

function setBorderFormatting(dataRange, rowFormatting, i, lastIndex) {
    let borders = rowFormatting[i]?.customFormatting?.borders;
    if (!borders) return;
    let row = dataRange.getRow(i);
    const allBordersExcept = [Excel.BorderIndex.edgeTop, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.insideVertical];
    if (borders?.all) {
        for (const border of allBordersExcept) {
            if (i === 0 && border === Excel.BorderIndex.edgeTop) dataRange.getRow(i).format.borders.getItem(Excel.BorderIndex.edgeTop).style = rowFormatting[i]?.customFormatting?.borders?.top?.style || rowFormatting[i]?.customFormatting?.borders?.all?.style;
            if ((!rowFormatting[i-1]?.customFormatting?.borders?.bottom?.style) && border === Excel.BorderIndex.edgeTop) continue;
            if ((!rowFormatting[i+1]?.customFormatting?.borders?.all?.style) && border === Excel.BorderIndex.edgeBottom) continue;
            if (i === lastIndex && border === Excel.BorderIndex.edgeBottom) continue;

            setBorder(row, border, borders.all.style, borders.all.color);
        }
    }
    if (borders?.top) setBorder(row, Excel.BorderIndex.edgeTop, borders.top.style, borders.top.color);
    if (borders?.right) setBorder(row, Excel.BorderIndex.edgeRight, borders.right.style, borders.right.color);
    if (borders?.bottom) setBorder(row, Excel.BorderIndex.edgeBottom, borders.bottom.style, borders.bottom.color);
    if (borders?.left) setBorder(row, Excel.BorderIndex.edgeLeft, borders.left.style, borders.left.color);
    if (borders?.insideVertical) setBorder(row, Excel.BorderIndex.insideVertical, borders.insideVertical.style, borders.insideVertical.color);
    if (borders?.insideHorizontal) setBorder(row, Excel.BorderIndex.insideHorizontal, borders.insideHorizontal.style, borders.insideHorizontal.color);
    if (borders?.diagonalUp) setBorder(row, Excel.BorderIndex.diagonalUp, borders.diagonalUp.style, borders.diagonalUp.color);
    if (borders?.diagonalDown) setBorder(row, Excel.BorderIndex.diagonalDown, borders.diagonalDown.style, borders.diagonalDown.color);
}


function setFirstRowFormatting(dataRange, rowFormatting) {
    for (let i = 0;i<rowFormatting.length;i++) {
        let rowRange = dataRange.getRow(i);
        setFormatting(rowRange, rowFormatting[i]);
        let rowHeight = rowFormatting[i]?.customFormatting?.format?.rowHeight;
        if (rowHeight) rowRange.format.rowHeight = rowHeight;
    }
}

function setSecondRowFormatting(dataRange, rowFormatting) {
    for (let i = 0;i<rowFormatting.length;i++) {
        let rowRange = dataRange.getRow(i);
        setCustomFormatting(rowRange, rowFormatting[i]);
        setBorderFormatting(dataRange, rowFormatting, i, rowFormatting.length-1);
    }
}

export {clearSheet, darkenColor, getData, getHeaderData, getMetaData, getDefaultStyle, getImage, processImages, mergeRanges, removeAllImages, setCustomFormatting, setHeaderFormatting, setFormatting, setFirstBodyFormatting, setSecondRowFormatting, setMetaData, setHeader};