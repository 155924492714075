import React, { useContext, useEffect } from "react";
import {
  useToastController,
  Toast,
  ToastTitle,
  ToastTrigger,
  ToastBody,
  Text,
  ToastFooter,
  Toaster,
  Link,
  Button,
  ToastIntent,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { ClientContext } from "./App";
import { ClientInfoContext } from "../models/client";
import { ToastType } from "../models/toast";

interface Props {
  id: string;
  intent: ToastIntent;
  title: string;
  body: string | null;
  toastList: ToastType[] | [];
  setToastList: React.Dispatch<React.SetStateAction<ToastType[] | []>>;
}

const ToastControl: React.FC<Props> = ({ id, intent, title, body, toastList, setToastList }) => {
  const clientInfo: ClientInfoContext = useContext(ClientContext);
  const { dispatchToast } = useToastController(id);
  const toastTimeout = 3000;

  useEffect(() => {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />
            </ToastTrigger>
          }
        >
          {title}
        </ToastTitle>

        {body && (
          <ToastBody>
            <Text>{body}</Text>
          </ToastBody>
        )}

        {intent === "error" && (
          <ToastFooter>
            <Link href={`mailto:${clientInfo.contact}`}>Contact us</Link>
          </ToastFooter>
        )}
      </Toast>,
      { position: "top", intent: intent, timeout: toastTimeout }
    );
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const nextToastList = toastList.filter((toast) => toast.id !== id);
      setToastList(nextToastList);
    }, toastTimeout);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Toaster toasterId={id} />
    </>
  );
};
export default ToastControl;
