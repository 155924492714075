import React, { useRef, useEffect, useState } from "react";
import { Button, Field, Input } from "@fluentui/react-components";
import { Dismiss12Regular, ChevronDown16Regular } from "@fluentui/react-icons";
import {
  Wrapper,
  FieldWrapper,
  DropdownWrapper,
  InputSection,
  CustomizeInput,
  InputWrapper,
  DropdownList,
  DropdownListItem,
} from "./common.styles";
import ModalTitle from "../Modals/ModalTitle";
import NewCheckbox from "./NewCheckbox";
import { optionItemMouseEnter, optionItemMouseLeave } from "../../helpers/mouseEvent";

interface Props {
  originalOptionList: any[];
  titleLabel: string;
  inputLabel: string;
  checkboxLabel: string;
  name: string;
  nameDisplay: string;
  nameStore: string;
  optionDesc: string | null;
  isMandatory: boolean;
  isDisabled: boolean;
  isErrMsg: boolean;
  err: null | string;
  optionSelectChange: (name: string, value: string) => void;
  isClearClicked: boolean;
  setClearClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewSelect: React.FC<Props> = ({
  originalOptionList,
  titleLabel,
  inputLabel,
  checkboxLabel,
  name,
  nameDisplay,
  nameStore,
  optionDesc,
  isMandatory,
  isDisabled,
  isErrMsg,
  err,
  optionSelectChange,
  isClearClicked,
  setClearClicked,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [activeOptionIdx, setActiveOptionIdx] = useState<number>(-1);
  const [selectedOptionIdx, setSelectedOptionIdx] = useState<number>(-1);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [storagedOption, setStoragedOption] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [isChecked, setChecked] = useState<boolean>(false);
  const [filteredOptionList, setFilteredOptionList] = useState<any[]>([...originalOptionList]);

  useEffect(() => {
    let nextOptionList;
    if (isChecked) {
      if (optionDesc) {
        nextOptionList = originalOptionList.filter(
          (option) =>
            option[nameDisplay]?.toLowerCase().includes(searchValue.toLowerCase()) ||
            option[nameStore]?.toLowerCase().includes(searchValue.toLowerCase())
        );
      } else {
        nextOptionList = originalOptionList.filter((option) =>
          option[nameDisplay]?.toLowerCase().includes(searchValue.toLowerCase())
        );
      }
    } else {
      if (optionDesc) {
        nextOptionList = originalOptionList
          .filter(({ isHidden }) => !isHidden)
          .filter(
            (option) =>
              option[nameDisplay]?.toLowerCase().includes(searchValue.toLowerCase()) ||
              option[nameStore]?.toLowerCase().includes(searchValue.toLowerCase())
          );
      } else {
        nextOptionList = originalOptionList
          .filter(({ isHidden }) => !isHidden)
          .filter((option) => option[nameDisplay]?.toLowerCase().includes(searchValue.toLowerCase()));
      }
    }

    setFilteredOptionList(nextOptionList);
  }, [isChecked, searchValue, originalOptionList]);

  // Scroll into view logic when activeOptionIdx changes
  useEffect(() => {
    if (activeOptionIdx >= 0 && optionRefs?.current[activeOptionIdx]) {
      optionRefs.current[activeOptionIdx]?.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeOptionIdx]);

  // Handle dropdown opening and scrolling into the selected option
  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
      searchInputRef.current?.focus();
      document.querySelector(".input-wrapper")?.classList.add("active");
    } else {
      setSearchValue(selectedOption || "");
      inputRef.current?.focus();
      document.querySelector(".input-wrapper")?.classList.remove("active");
    }

    // Find the selected index based on the stored option
    let selectedIdx: number;
    if (isChecked) {
      selectedIdx = originalOptionList.findIndex((option) => option[nameStore] === storagedOption);
    } else {
      selectedIdx = originalOptionList
        .filter(({ isHidden }) => !isHidden)
        .findIndex((option) => option[nameStore] === storagedOption);
    }

    setActiveOptionIdx(selectedIdx >= 0 ? selectedIdx : 0);
    setSelectedOptionIdx(selectedIdx);

    if (selectedIdx === -1) {
      setSelectedOption("");
      setStoragedOption("");
      optionSelectChange("", "");
    }

    // Use setTimeout to defer the scrollIntoView until after rendering completes
    const timeoutId = setTimeout(() => {
      if (selectedIdx >= 0 && optionRefs?.current[selectedIdx]) {
        optionRefs.current[selectedIdx]?.scrollIntoView({
          block: "start",
          inline: "nearest",
        });
      }
    }, 0);

    // Cleanup function to clear the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, isChecked, originalOptionList]);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
    if (!isOpen) {
      inputRef.current?.focus();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setOpen(true);
    setActiveOptionIdx(0);
  };

  const handleSelect = (targetName: string, targetValue: string, targetIdx: number) => {
    if (targetName !== selectedOption) {
      setSelectedOption(targetName);
      setStoragedOption(targetValue);
      setSearchValue("");
      setActiveOptionIdx(targetIdx);
      setSelectedOptionIdx(targetIdx);
      optionSelectChange(targetName, targetValue);
    }

    setOpen(false);
  };

  const handleClearClick = () => {
    setSelectedOption("");
    setStoragedOption("");
    optionSelectChange("", "");
    setSearchValue("");
    setActiveOptionIdx(-1);
    setSelectedOptionIdx(-1);
  };

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Enter":
        e.preventDefault();
        setOpen(true);
        break;
      case "Escape":
        e.preventDefault();
        handleClearClick();
        break;
      default:
        break;
    }
  };

  // Handle key navigation (Up, Down, Tab, Enter, Escape)
  const handleDropdownKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          // Handle Shift + Tab (move backwards = ArrowUp)
          setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        } else {
          // Handle Tab (move forward = ArrowDown)
          setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (activeOptionIdx >= 0) {
          handleSelect(
            filteredOptionList[activeOptionIdx][nameDisplay],
            filteredOptionList[activeOptionIdx][nameStore],
            activeOptionIdx
          );
        }

        break;
      case "Escape":
        e.preventDefault();
        if (searchValue.length > 0) {
          handleClearClick();
        } else {
          setOpen(false);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isClearClicked) {
      handleClearClick();
      setChecked(false);
      setClearClicked(false);
    }
  }, [isClearClicked]);

  return (
    <Wrapper>
      <FieldWrapper className={isErrMsg ? "" : "wrapper_non-validation"}>
        <Field
          label={inputLabel}
          size="medium"
          onKeyDown={handleInputKeyDown}
          required={isMandatory}
          validationState={err ? "error" : "none"}
          validationMessage={err === "empty" ? "This field cannot be empty" : null}
        >
          <Input
            ref={inputRef}
            className="input-dropdown"
            size="medium"
            placeholder="..."
            value={selectedOption ? selectedOption : ""}
            onChange={() => {
              setOpen(true);
            }}
            onClick={toggleDropdown}
            contentAfter={<ChevronDown16Regular />}
            disabled={isDisabled}
          />
        </Field>
      </FieldWrapper>

      <NewCheckbox
        className="input-dropdown"
        label={checkboxLabel}
        isChecked={isChecked}
        handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleCheckboxChange();
          }
        }}
        handleChange={handleCheckboxChange}
      />

      <DropdownWrapper className={isOpen ? "active" : ""} onKeyDown={handleDropdownKeyDown}>
        <ModalTitle title={titleLabel} action={() => setOpen(false)} />

        <InputSection>
          <NewCheckbox className="" label={checkboxLabel} isChecked={isChecked} handleChange={handleCheckboxChange} />

          <InputWrapper className="input-wrapper">
            <CustomizeInput ref={searchInputRef} placeholder="..." value={searchValue} onChange={handleInputChange} />
            <Button appearance="transparent" icon={<Dismiss12Regular />} size="small" onClick={handleClearClick} />
          </InputWrapper>
        </InputSection>

        <DropdownList className="dropdown-list" role="listbox">
          {filteredOptionList.length > 0 ? (
            filteredOptionList.map((option, idx) => (
              <DropdownListItem
                key={`select_${name}_option-${option[nameStore]}-${idx}`}
                id={`select_${name}_option-${option[nameStore]}-${idx}`}
                ref={(el) => (optionRefs.current[idx] = el)}
                tabIndex={0}
                role="option"
                aria-selected={idx === selectedOptionIdx}
                className={`dropdown-item ${
                  idx === selectedOptionIdx ? "selected" : idx === activeOptionIdx ? "hovered" : ""
                }`}
                onClick={() => handleSelect(option[nameDisplay], option[nameStore], idx)}
                onMouseEnter={() => optionItemMouseEnter(`select_${name}_option-${option[nameStore]}-${idx}`)}
                onMouseLeave={() => optionItemMouseLeave(`select_${name}_option-${option[nameStore]}-${idx}`)}
                data-value={option[nameStore]}
              >
                {option[nameDisplay]}
              </DropdownListItem>
            ))
          ) : (
            <DropdownListItem>No item found</DropdownListItem>
          )}
        </DropdownList>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default NewSelect;
