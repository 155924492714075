// "loadTableStructure" | "loadTable" | "insertRow" | "editRow" | "copyRow" | "deleteRow" | "loadReport";

export const toastDetailList = {
  loadTableStructure: {
    success: {
      title: "Selected table structure loaded successfully",
      body: null,
    },
    warning: {
      title: "Empty table structure",
      body: "The table structure is empty. No data available.",
    },
    error: {
      title: "Load table structure failed",
      body: "Please try again. If the issue persists, contact support for further assistance.",
    },
  },
  loadTable: {
    success: {
      title: "Selected table loaded successfully",
      body: null,
    },
    warning: {
      title: "Empty table",
      body: "The table is empty. No data available.",
    },
    error: {
      title: "Load table failed",
      body: "Please try again. If the issue persists, contact support for further assistance.",
    },
  },
  insertRow: {
    success: {
      title: "Selected row inserted successfully",
      body: null,
    },
    error: {
      title: "Insert failed",
      body: "Please try again. If the issue persists, please contact us for further assistance.",
    },
  },
  editRow: {
    success: {
      title: "Selected row updated successfully",
      body: null,
    },
    error: {
      title: "Update failed",
      body: "Please try again. If the issue persists, please contact us for further assistance.",
    },
  },
  copyRow: {
    success: {
      title: "Selected row copied successfully",
      body: null,
    },
    error: {
      title: "Copy failed",
      body: "Please try again. If the issue persists, please contact us for further assistance.",
    },
  },
  deleteRow: {
    success: {
      title: "Selected row deleted successfully",
      body: null,
    },
    error: {
      title: "Delete failed",
      body: "Please try again. If the issue persists, please contact us for further assistance.",
    },
  },
  loadReport: {
    success: {
      title: "Selected report loaded successfully",
      body: null,
    },
    warning: {
      title: "Empty report",
      body: "The report is empty. No data available.",
    },
    error: {
      title: "Load report failed",
      body: "Please try again. If the issue persists, contact support for further assistance.",
    },
  },
};
