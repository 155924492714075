/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Tooltip, Button, Spinner } from "@fluentui/react-components";
import { ArrowRight20Regular } from "@fluentui/react-icons";
import { Wrapper, Inner, SelectBtnWrapper, FieldsWrapper } from "./styles.common";
import { Col } from "../Fields/styles.common";
import Loader from "../Fields/Loader";
import Select from "../Fields/Select";
import Buttons from "../Buttons";
import Message from "../Message";
import ToastControl from "../ToastControl";
import { ToastType } from "../../models/toast";
import { messageList } from "../../utils/messageList";
import { toastDetailList } from "../../config/toastConfig";
import { fetchTableListData, loadTableStructureData, loadTableData } from "../../utils/apiCalls";
import { importTableStructureIntoExcel } from "../../officeDocuments/table-document";
import TableToolbar from "../TableToolbar";

interface SelectedTable {
  displayName: string;
  storeName: string;
  err: null | string;
}

interface TblList {
  id: string;
  name: string;
  displayName: string;
  isHidden: boolean;
}

const TableWindow = () => {
  const [isTableListLoading, setTableListLoading] = useState<boolean>(true);
  const [tableList, setTableList] = useState<TblList[]>([]);
  const [tableListErr, setTableListErr] = useState<boolean>(false);

  const [selectedTable, setSelectedTable] = useState<SelectedTable>({ displayName: "", storeName: "", err: null });
  const [isTableClearClicked, setTableClearClicked] = useState<boolean>(false);

  const [isSelectedTableStructureLoading, setSelectedTableStructureLoading] = useState<boolean>(false);
  const [isSelectedTableStructureLoaded, setSelectedTableStructureLoaded] = useState<boolean>(false);
  const [isSelectedTableStructureEmpty, setSelectedTableStructureEmpty] = useState<boolean>(false);
  const [isSelectedTableStructureErr, setSelectedTableStructureErr] = useState<boolean>(false);

  const [isSelectedTableLoading, setSelectedTableLoading] = useState<boolean>(false);
  const [isSelectedTableLoaded, setSelectedTableLoaded] = useState<boolean>(false);
  const [isSelectedTableEmpty, setSelectedTableEmpty] = useState<boolean>(false);
  const [isSelectedTableErr, setSelectedTableErr] = useState<boolean>(false);

  const [activeModalWindow, setActiveModalWindow] = useState<string | null>(null);

  const [toastList, setToastList] = useState<ToastType[] | []>([]);

  useEffect(() => {
    if (isTableListLoading) {
      getTableList();
    }
  }, [isTableListLoading]);

  useEffect(() => {
    if (isSelectedTableLoading) {
      loadTable();
    }
  }, [isSelectedTableLoading]);

  const getTableList = async () => {
    try {
      setTableListErr(false);
      setTableList([]);

      const sortedTableListData = await fetchTableListData();

      setTableList(sortedTableListData);
      setTableListLoading(false);
    } catch (err: any) {
      setTableListLoading(false);
      setTableList([]);
      setTableListErr(err);
    }
  };

  const loadTableStructure = async (selectedTblName: string) => {
    try {
      setSelectedTableStructureLoading(true);
      setSelectedTableStructureLoaded(false);
      setSelectedTableStructureErr(false);
      setSelectedTableStructureEmpty(false);

      const data = await loadTableStructureData(selectedTblName);
      importTableStructureIntoExcel(data);

      if (data.length === 0) {
        setSelectedTableStructureEmpty(true);
      } else {
        setSelectedTableStructureEmpty(false);
      }
      setSelectedTableStructureLoading(false);
      setSelectedTableStructureLoaded(true);
    } catch (err: any) {
      setSelectedTableStructureLoading(false);
      setSelectedTableStructureLoaded(false);
      setSelectedTableStructureEmpty(false);
      setSelectedTableStructureErr(err);
    }
  };

  const loadTable = async () => {
    try {
      setSelectedTableLoading(true);
      setSelectedTableLoaded(false);
      setSelectedTableErr(false);
      setSelectedTableEmpty(false);

      const data = await loadTableData();

      if (data.length === 0) {
        setSelectedTableEmpty(true);
      } else {
        setSelectedTableEmpty(false);
      }
      setSelectedTableLoading(false);
      setSelectedTableLoaded(true);
    } catch (err: any) {
      setSelectedTableLoading(false);
      setSelectedTableLoaded(false);
      setSelectedTableEmpty(false);
      setSelectedTableErr(err);
    }
  };

  const handleTblChange = (name: string, value: string) => {
    setSelectedTable({ displayName: name, storeName: value, err: null });
  };

  const handleClearClick = () => {
    setTableClearClicked(true);
    setSelectedTable({ displayName: "", storeName: "", err: null });
  };

  const handleLoadClick = () => {
    if (!checkValid()) return;
    loadTableStructure(selectedTable.storeName);
  };

  const checkValid = () => {
    let valid = true;

    if (selectedTable.err) {
      valid = false;
      return;
    }

    if (selectedTable?.storeName.length === 0) {
      setSelectedTable({ displayName: "", storeName: "", err: "empty" });
      valid = false;
    }

    return valid;
  };

  useEffect(() => {
    let nextToastList = [...toastList];
    if (isSelectedTableStructureLoaded) {
      if (isSelectedTableStructureEmpty) {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "warning",
          title: toastDetailList.loadTableStructure.warning.title,
          body: toastDetailList.loadTableStructure.warning.body,
        });
      } else {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "success",
          title: toastDetailList.loadTableStructure.success.title,
          body: toastDetailList.loadTableStructure.success.body,
        });
      }
      setToastList(nextToastList);

      return;
    }

    if (isSelectedTableStructureErr) {
      nextToastList.push({
        id: crypto.randomUUID(),
        intent: "error",
        title: toastDetailList.loadTableStructure.error.title,
        body: toastDetailList.loadTableStructure.error.body,
      });

      setToastList(nextToastList);
      return;
    }
  }, [isSelectedTableStructureEmpty, isSelectedTableStructureLoaded, isSelectedTableStructureErr]);

  useEffect(() => {
    let nextToastList = [...toastList];
    if (isSelectedTableLoaded) {
      if (isSelectedTableEmpty) {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "warning",
          title: toastDetailList.loadTable.warning.title,
          body: toastDetailList.loadTable.warning.body,
        });
      } else {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "success",
          title: toastDetailList.loadTable.success.title,
          body: toastDetailList.loadTable.success.body,
        });
      }
      setToastList(nextToastList);

      return;
    }

    if (isSelectedTableStructureErr) {
      nextToastList.push({
        id: crypto.randomUUID(),
        intent: "error",
        title: toastDetailList.loadTable.error.title,
        body: toastDetailList.loadTable.error.body,
      });

      setToastList(nextToastList);
      return;
    }
  }, [isSelectedTableEmpty, isSelectedTableLoaded, isSelectedTableErr]);

  return (
    <Wrapper>
      {toastList.length > 0 &&
        toastList.map(({ id, intent, title, body }) => (
          <ToastControl
            key={id}
            id={id}
            intent={intent}
            title={title}
            body={body}
            toastList={toastList}
            setToastList={setToastList}
          />
        ))}

      <Inner className="table-window">
        <TableToolbar
          isSelectedTableLoaded={isSelectedTableLoaded}
          activeModalWindow={activeModalWindow}
          setActiveModalWindow={setActiveModalWindow}
        />

        <FieldsWrapper>
          {isTableListLoading ? (
            <Col>
              <Loader />
            </Col>
          ) : tableListErr ? (
            <Col>
              <Message
                type="error"
                title="Error"
                desc={messageList.loadErr}
                isMainBtn={true}
                mainBtnTxt="Reload"
                handleMainBtnClick={() => setTableListLoading(true)}
              />
            </Col>
          ) : (
            <>
              <SelectBtnWrapper>
                <Select
                  originalOptionList={tableList}
                  titleLabel="Select a table"
                  inputLabel="Table name"
                  checkboxLabel="Show all hidden tables"
                  name="tbl"
                  nameDisplay="displayName"
                  nameStore="name"
                  optionDesc="name"
                  isMandatory={true}
                  isDisabled={false}
                  isErrMsg={false}
                  err={selectedTable.err}
                  optionSelectChange={handleTblChange}
                  isClearClicked={isTableClearClicked}
                  setClearClicked={setTableClearClicked}
                />

                <Tooltip content="Confirm Table" relationship="label">
                  <Button
                    size="large"
                    appearance="primary"
                    icon={isSelectedTableStructureLoading ? <Spinner size="tiny" /> : <ArrowRight20Regular />}
                    onClick={() => {
                      if (!isSelectedTableStructureLoading && selectedTable.displayName.length !== 0) {
                        handleLoadClick();
                      }
                    }}
                    disabled={selectedTable.displayName.length === 0}
                  />
                </Tooltip>
              </SelectBtnWrapper>
            </>
          )}
        </FieldsWrapper>
        {!isTableListLoading && !tableListErr && (
          <Buttons
            mainBtnTxt="Load table"
            secondaryBtnTxt="Clear all"
            isMainBtnLoading={false}
            isMainBtnDisabled={true}
            isSecondaryBtnDisabled={isSelectedTableStructureLoading}
            handleConfirmClick={() => setSelectedTableLoading(true)}
            handleCancelClick={handleClearClick}
          />
        )}
      </Inner>
    </Wrapper>
  );
};
export default TableWindow;
