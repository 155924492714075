/* eslint-disable */
/* global Excel console */
import {defaultFormattingOptions, styles, columnWidth as columnWidths} from "../config/excelConfig.js";
import { getReportStyle, getImage } from "../utils/apiCalls";
import {
  clearSheet,
  darkenColor,
  getData,
  getHeaderData,
  getMetaData,
  mergeRanges,
  processImages,
  removeAllImages,
  setCustomFormatting,
  setFirstBodyFormatting,
  setFormatting, setHeader,
  setMetaData,
  setSecondRowFormatting
} from "./excelHelper.js";



export const importInstantReportIntoExcel = async (report) => {
  if (report.length === 0) return;
  console.log(report);
  try {
    await Excel.run(async (context) => {
      OfficeExtension.config.debugInfo = true;
      OfficeExtension.config.extendedErrorLogging = true;
      context.workbook.application.suspendApiCalculationUntilNextSync();
      context.workbook.application.suspendScreenUpdatingUntilNextSync();
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const epKey = Object.keys(report[0]).includes('EP') ? 'EP' : 'ep';
      const { data, columnFormatting, rowFormatting, images, merges } = getData(report, epKey);
      const { headerData, headerFormatting } = getHeaderData(report, epKey);
      const {metaDataFormatting, metaDataValues} = getMetaData(report, epKey);
      console.log(data);
      console.log(columnFormatting);
      console.log(rowFormatting);
      console.log(images);
      console.log(merges);
      console.log(headerData);
      console.log(headerFormatting);
      console.log(metaDataFormatting);
      console.log(metaDataValues);
      // if (metaDataValues.length !== metaDataFormatting.length) {
      //   console.log(`Possible error at getMetaData()! Different length of arr`);
      //   return {error: "Inconsistent metadata!", data: metaDataValues, format: metaDataFormatting};
      // }
      // if (data?.length !== rowFormatting?.length  || data[0]?.length !== columnFormatting?.length) {
      //   console.log(`Possible error at getData()! Different length of arr`);
      //   console.log(columnFormatting);
      //   console.log(rowFormatting);
      //   console.log(data);
      //   return {error: "Inconsistent data!", data: data, columns: columnFormatting, rows: rowFormatting};
      // }

      let dataRange, headerRange = null;

      clearSheet(sheet);
      await removeAllImages(sheet, context);
      setMetaData(sheet, metaDataValues, metaDataFormatting);
      if (headerData && headerFormatting) {
        headerRange = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow().getResizedRange(0, headerData.length-1);
        setHeader(headerRange, headerData, headerFormatting);
      }
      if (data.length > 0) dataRange = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow().getResizedRange(data.length-1, data[0].length-1);

      if (data.length > 0) {
        const batchSize = 10000;
        for (let i = 0; i < data.length; i += batchSize) {
          const batchData = data.slice(i, i + batchSize);
          const batchRowFormatting = rowFormatting.slice(i, i + batchSize);
          const batchRange = sheet.getUsedRange().getLastRow().getCell(1, 0).getResizedRange(batchData.length - 1, Object.values(batchData[0]).length - 1);
          batchRange.values = batchData;
          setFirstBodyFormatting(batchRange, batchRowFormatting, batchData);
          await context.sync();
        }
      }

      context.workbook.application.suspendScreenUpdatingUntilNextSync();

      if (columnFormatting.length > 0 && data.length > 0) {
        for (let i = 0;i<columnFormatting.length;i++){
          let column = dataRange.getColumn(i);
          setCustomFormatting(column, columnFormatting[i]);
          if (headerRange && columnFormatting[i]?.defaultFormatting) setFormatting(headerRange.getColumn(i), columnFormatting[i]);
          if (columnFormatting[i]?.defaultFormatting) setFormatting(dataRange.getColumn(i), columnFormatting[i]);
          if (headerRange && columnFormatting[i]?.customFormatting?.format?.fill?.color) headerRange.getColumn(i).format.fill.color = darkenColor(columnFormatting[i].customFormatting?.format?.fill?.color);
          if (columnFormatting[i]?.customFormatting?.format?.fill?.color) dataRange.getColumn(i).format.fill.color = columnFormatting[i].customFormatting?.format?.fill?.color;

        }
      }


      if (headerRange) headerRange.untrack();
      if (data.length > 0) {
        const batchSize = 10000;
        for (let i = 0; i < data.length; i += batchSize) {
          const batchRowFormatting = rowFormatting.slice(i, i + batchSize);
          const batchData = data.slice(i, i + batchSize);
          const batchRange = dataRange.getUsedRange().getCell(0, 0).getResizedRange(batchData.length - 1, Object.values(batchData[0]).length - 1);
          setSecondRowFormatting(batchRange, batchRowFormatting);
          await context.sync();
        }
      }

      if (images) await processImages(sheet ,images[0]);
      if (dataRange && merges) mergeRanges(dataRange, merges[0]);
      await context.sync();

      return context.sync();
    });
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return e;
  }

}