import React from "react";
import styled from "styled-components";
import MainTabs from "./Nav/MainTabs";
import TableWindow from "./Modules/TableWindow";
import InstantReport from "./Modules/InstantReport";
import Mailing from "./Modules/Mailing";

const Wrapper = styled.div`
  /* width: 100%; */
  height: 100vh;
  display: grid;
  grid-template-rows: max-content 1fr;

  p {
    margin: 0;
    padding: 0;
  }

  .fui-DatePicker__popupSurface,
  .fui-CalendarPicker__buttonRow,
  .fui-CalendarPicker__itemButton {
    font-family: "Segoe UI", "sans-serif" !important;
  }

  .fui-CalendarDayGrid__dayButton {
    font-family: "Segoe UI", "sans-serif" !important;
  }
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;

  /* Apply thin scrollbars across WebKit browsers */
  &::-webkit-scrollbar {
    width: 6px; /* Thin width for the scrollbar */
  }

  /* Track (background of scrollbar) */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for track */
    border-radius: 10px;
  }

  /* Handle (the actual scrolling part) */
  &::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the handle */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }
`;

interface Props {
  selectedValue: "tableWindow" | "instantReport" | "mailing";
  setSelectedValue: React.Dispatch<React.SetStateAction<"tableWindow" | "instantReport" | "mailing">>;
}

const MainContent: React.FC<Props> = ({ selectedValue, setSelectedValue }) => {
  return (
    <Wrapper>
      <MainTabs selectedValue={selectedValue} setSelectedValue={setSelectedValue} />
      <Inner>
        {selectedValue === "tableWindow" && <TableWindow />}
        {selectedValue === "instantReport" && <InstantReport />}
        {selectedValue === "mailing" && <Mailing />}
      </Inner>
    </Wrapper>
  );
};

export default MainContent;
