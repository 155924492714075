import React from "react";
import { Label } from "@fluentui/react-components";

interface Props {
  id: string;
  label: string;
}

const CustomLabel: React.FC<Props> = ({ id, label }) => (
  <Label htmlFor={id} weight="semibold">
    {label}
  </Label>
);

export default CustomLabel;
