/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Divider } from "@fluentui/react-components";
import { Wrapper, Inner, FieldsWrapper } from "./styles.common";
import { Col } from "../Fields/styles.common";
import Loader from "../Fields/Loader";
import Select from "../Fields/Select";
import Buttons from "../Buttons";
import ToastControl from "../ToastControl";
import Message from "../Message";
import Parameters from "../Parameters";
// import Preview from "../Preview";
import { importInstantReportIntoExcel } from "../../officeDocuments/report-document";
import { fetchIntRptListData, fetchIntRptParaData, loadIntRptData } from "../../utils/apiCalls";
import { multiselectJoinedChar } from "../../utils/separators";
import { messageList } from "../../utils/messageList.js";
import { ToastType } from "../../models/toast";
import { toastDetailList } from "../../config/toastConfig";
import { RptList, RptPara, SelectedRpt, SelectPara } from "../../models/instantReport";

const InstantReport = () => {
  const [isRptListLoading, setRptListLoading] = useState<boolean>(true);
  const [rptList, setRptList] = useState<RptList[] | []>([]);
  const [rptListErr, setRptListErr] = useState<boolean>(false);

  const [selectedRpt, setSelectedRpt] = useState<SelectedRpt>({ displayName: "", storeName: "", err: null });
  const [isRptClearClicked, setRptClearClicked] = useState<boolean>(false);

  const [isParaListLoading, setParaListLoading] = useState<boolean>(false);
  const [paraList, setParaList] = useState<RptPara[] | any[]>([]);
  const [paraListErr, setParaListErr] = useState<boolean>(false);
  const [selectedPara, setSelectedPara] = useState<SelectPara>({});

  const [isSelectedRptLoading, setSelectedRptLoading] = useState<boolean>(false);
  const [selectedRptLoaded, setSelectedRptLoaded] = useState<boolean>(false);
  const [selectedRptErr, setSelectedRptErr] = useState<boolean>(false);
  const [isSelectedRptEmpty, setSelectedRptEmpty] = useState<boolean>(false);

  const [toastList, setToastList] = useState<ToastType[] | []>([]);

  const getIntRptList = async () => {
    try {
      setRptListErr(false);
      setRptList([]);

      const rptData = await fetchIntRptListData();

      setRptList(rptData);
      setRptListLoading(false);
    } catch (err: any) {
      setRptListLoading(false);
      setRptList([]);
      setRptListErr(err);
    }
  };

  const getIntRptPara = async (selectedRpt: any) => {
    try {
      setParaListLoading(true);
      setParaListErr(false);
      setParaList([]);
      setSelectedPara({});

      const paraData = await fetchIntRptParaData(selectedRpt);

      setParaList(paraData);
      setParaListLoading(false);
    } catch (err: any) {
      setParaListLoading(false);
      setParaList([]);
      setParaListErr(err);
    }
  };

  const loadReport = async (selectedIntRptName: string, paraArr: any[]) => {
    try {
      setSelectedRptLoading(true);
      setSelectedRptLoaded(false);
      setSelectedRptErr(false);
      setSelectedRptEmpty(false);

      let downloadTime = performance.now();
      const data = await loadIntRptData(selectedIntRptName, paraArr);
      console.log(`GET time: ${performance.now() - downloadTime} ms`);
      let perfStart = performance.now();
      await importInstantReportIntoExcel(data);
      console.log(`Execution time: ${performance.now() - perfStart} ms`)

      if (data.length === 0) {
        setSelectedRptEmpty(true);
      } else {
        setSelectedRptEmpty(false);
      }

      setSelectedRptLoaded(true);
      setSelectedRptLoading(false);
    } catch (err: any) {
      setSelectedRptLoading(false);
      setSelectedRptLoaded(false);
      setSelectedRptEmpty(false);
      setSelectedRptErr(err);
    }
  };

  useEffect(() => {
    if (isRptListLoading) {
      getIntRptList();
    }
  }, [isRptListLoading]);

  const handleIntRptChange = (name: string, value: string) => {
    setSelectedRpt({ displayName: name, storeName: value, err: null });
    if (value.length > 0) {
      getIntRptPara(value);
    } else {
      setParaListErr(false);
      setParaList([]);
      setSelectedPara({});
    }
  };

  const handleClearClick = () => {
    setRptClearClicked(true);
    setSelectedRpt({ displayName: "", storeName: "", err: null });
    setParaList([]);
    setSelectedPara({});
  };

  const handleLoadClick = () => {
    if (!checkValid()) return;
    let paraArr: string[] = [];
    for (const key in selectedPara) {
      if (Object.prototype.hasOwnProperty.call(selectedPara, key)) {
        const selectedParaValue = selectedPara[key].value;

        if (typeof selectedParaValue === "string" && selectedParaValue.length > 0) {
          paraArr.push(`${key}='${encodeURIComponent(selectedParaValue)}'`);
        } else if (typeof selectedParaValue === "number" && selectedParaValue === 1) {
          paraArr.push(`${key}='${encodeURIComponent(selectedParaValue)}'`);
        } else if (Array.isArray(selectedParaValue) && selectedParaValue.length > 0) {
          const encodedArr = selectedParaValue?.map((value: string | number) => encodeURIComponent(value));
          const encodedStr = encodedArr.join(encodeURIComponent(multiselectJoinedChar));
          paraArr.push(`${key}='${encodedStr}'`);
        }
      }
    }

    loadReport(selectedRpt.storeName, paraArr);
  };

  const checkValid = () => {
    let valid = true;

    if (selectedRpt.err) {
      valid = false;
      return;
    }

    if (selectedRpt?.storeName.length === 0) {
      setSelectedRpt({ displayName: "", storeName: "", err: "empty" });
      valid = false;
    }

    setSelectedPara((prev) => {
      const copiedObj = { ...prev };
      for (const key in copiedObj) {
        if (Object.prototype.hasOwnProperty.call(copiedObj, key)) {
          if (copiedObj[key].isMandatory) {
            const copiedSelectedParaValue = copiedObj[key].value;
            if (typeof copiedSelectedParaValue === "string" && copiedSelectedParaValue.length === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else if (typeof copiedSelectedParaValue === "number" && copiedSelectedParaValue === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else if (Array.isArray(copiedSelectedParaValue) && copiedSelectedParaValue.length === 0) {
              copiedObj[key].err = "empty";
              valid = false;
            } else {
              copiedObj[key].err = null;
            }
          }
        }
      }
      return copiedObj;
    });

    return valid;
  };

  useEffect(() => {
    let nextToastList = [...toastList];
    if (selectedRptLoaded) {
      if (isSelectedRptEmpty) {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "warning",
          title: toastDetailList.loadReport.warning.title,
          body: toastDetailList.loadReport.warning.body,
        });
      } else {
        nextToastList.push({
          id: crypto.randomUUID(),
          intent: "success",
          title: toastDetailList.loadReport.success.title,
          body: toastDetailList.loadReport.success.body,
        });
      }
      setToastList(nextToastList);
      return;
    }

    if (selectedRptErr) {
      nextToastList.push({
        id: crypto.randomUUID(),
        intent: "error",
        title: toastDetailList.loadReport.error.title,
        body: toastDetailList.loadReport.error.body,
      });

      setToastList(nextToastList);
      return;
    }
  }, [isSelectedRptEmpty, selectedRptLoaded, selectedRptErr]);

  useEffect(() => {
    if (selectedRpt.displayName.length === 0) {
      setParaList([]);
      setSelectedPara({});
    }
  }, [selectedRpt.displayName]);

  return (
    <Wrapper>
      {toastList.length > 0 &&
        toastList.map(({ id, intent, title, body }) => (
          <ToastControl
            key={id}
            id={id}
            intent={intent}
            title={title}
            body={body}
            toastList={toastList}
            setToastList={setToastList}
          />
        ))}
      <Inner className="instant-report">
        <FieldsWrapper>
          {isRptListLoading ? (
            <Col>
              <Loader />
            </Col>
          ) : rptListErr ? (
            <Col>
              <Message
                type="error"
                title="Error"
                desc={messageList.loadErr}
                isMainBtn={true}
                mainBtnTxt="Reload"
                handleMainBtnClick={() => setRptListLoading(true)}
              />
            </Col>
          ) : (
            <>
              <Select
                originalOptionList={rptList}
                titleLabel="Select a report"
                inputLabel="Report name"
                checkboxLabel="Show all hidden reports"
                name="intRpt"
                nameDisplay="displayName"
                nameStore="name"
                optionDesc={null}
                isMandatory={true}
                isDisabled={isParaListLoading}
                isErrMsg={false}
                err={selectedRpt.err}
                optionSelectChange={handleIntRptChange}
                isClearClicked={isRptClearClicked}
                setClearClicked={setRptClearClicked}
              />
            </>
          )}

          {selectedRpt.storeName.length > 0 && <Divider className="divider">Parameters</Divider>}

          <Parameters
            isParaListLoading={isParaListLoading}
            paraList={paraList}
            paraListErr={paraListErr}
            selectedRpt={selectedRpt}
            selectedPara={selectedPara}
            setSelectedPara={setSelectedPara}
            getIntRptPara={getIntRptPara}
          />

          {/*{Object.keys(selectedPara).length > 0 && (*/}
          {/* <Preview rptName={selectedRpt.displayName} selectedPara={selectedPara} /> */}
          {/* )} */}
        </FieldsWrapper>

        {!isRptListLoading && !rptListErr && !paraListErr && (
          <Buttons
            mainBtnTxt="Load report"
            secondaryBtnTxt="Clear all"
            isMainBtnLoading={isSelectedRptLoading}
            isMainBtnDisabled={selectedRpt.displayName.length === 0 || isParaListLoading}
            isSecondaryBtnDisabled={isSelectedRptLoading || isParaListLoading}
            handleConfirmClick={handleLoadClick}
            handleCancelClick={handleClearClick}
          />
        )}
      </Inner>
    </Wrapper>
  );
};

export default InstantReport;
