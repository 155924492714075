import { createDarkTheme, createLightTheme } from "@fluentui/react-components";

const BlubinaCoreTheme = {
  10: "#020305",
  20: "#121723",
  30: "#19253E",
  40: "#1E3154",
  50: "#223D6C",
  60: "#264985",
  70: "#28569E",
  80: "#2964B8",
  90: "#3A71C9",
  100: "#567FCF",
  110: "#6D8DD5",
  120: "#829BDB",
  130: "#96A9E1",
  140: "#A9B8E6",
  150: "#BCC7EC",
  160: "#CFD6F1",
};

export const lightTheme = {
  ...createLightTheme(BlubinaCoreTheme),
};

export const darkTheme = {
  ...createDarkTheme(BlubinaCoreTheme),
};

darkTheme.colorBrandForeground1 = BlubinaCoreTheme[110]; // use brand[110] instead of brand[100]
darkTheme.colorBrandForeground2 = BlubinaCoreTheme[120]; // use brand[120] instead of brand[110]
