/* eslint-disable */
import React, { useEffect } from "react";
import { Checkbox, CheckboxProps, Field } from "@fluentui/react-components";
import { Wrapper } from "./styles.common";
import { SelectPara } from "../../models/instantReport";

interface Props {
  name: string;
  label: string;
  valueType: string;
  defaultValue: string;
  isMandatory: boolean;
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const ParaCheckbox: React.FC<Props> = ({
  name,
  label,
  valueType,
  defaultValue,
  isMandatory,
  selectedPara,
  setSelectedPara,
}) => {
  const numDefaultValue = +defaultValue;
  const paraName = name;
  const targetSelectedParaObj = selectedPara[paraName];

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        value: numDefaultValue ? numDefaultValue : 0,
        isMandatory: isMandatory,
        type: valueType,
        err: null,
      },
    }));
  }, []);

  const handleChange: CheckboxProps["onChange"] = () => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: targetSelectedParaObj.value === 1 ? 0 : 1, err: null },
    }));
  };

  return (
    <Wrapper className="wrapper_para-Checkbox">
      <Field
        required={isMandatory}
        validationState={targetSelectedParaObj?.err ? "error" : "none"}
        validationMessage={targetSelectedParaObj?.err === "empty" ? "This field should be checked" : null}
      >
        <Checkbox
          label={label}
          checked={
            targetSelectedParaObj?.value
              ? targetSelectedParaObj.value === 0
                ? false
                : true
              : numDefaultValue === 0
              ? false
              : true
          }
          onChange={handleChange}
        />
      </Field>
    </Wrapper>
  );
};

export default ParaCheckbox;
