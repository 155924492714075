import React from "react";
import styled from "styled-components";
import { Button, Tooltip, tokens } from "@fluentui/react-components";
import {
  TableCellEdit24Regular,
  CopySelect24Regular,
  TableStackBelow24Regular,
  TableDeleteRow24Regular,
  // TableResizeColumn24Regular,
  // DocumentTableSearch24Regular,
  TableLink24Regular,
} from "@fluentui/react-icons";
import { modalList, modalTooltip } from "../config/modalConfig";

const Wrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 14px;
  background-color: ${tokens.colorNeutralBackground1};
  box-shadow: ${tokens.shadow2};
  margin-bottom: 30px;
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 26px;
`;

interface Props {
  isSelectedTableLoaded: boolean;
  activeModalWindow: string | null;
  setActiveModalWindow: React.Dispatch<React.SetStateAction<string | null>>;
}

const TableToolbar: React.FC<Props> = ({ isSelectedTableLoaded, activeModalWindow, setActiveModalWindow }) => {
  return (
    <Wrapper>
      <Inner>
        <BtnGroup>
          <Tooltip content={modalTooltip.insertRow} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<TableStackBelow24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.insertRow)}
              disabled={!isSelectedTableLoaded}
            />
          </Tooltip>
          <Tooltip content={modalTooltip.editRow} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<TableCellEdit24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.editRow)}
              disabled={!isSelectedTableLoaded}
            />
          </Tooltip>
          <Tooltip content={modalTooltip.copyRow} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<CopySelect24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.copyRow)}
              disabled={!isSelectedTableLoaded}
            />
          </Tooltip>
          <Tooltip content={modalTooltip.deleteRow} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<TableDeleteRow24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.deleteRow)}
              disabled={!isSelectedTableLoaded}
            />
          </Tooltip>
        </BtnGroup>
        {/* <BtnGroup>
          <Tooltip content={modalTooltip.filterTableColumn} relationship="label" withArrow>
            <Button
              size="large"
              appearance="subtle"
              icon={<TableResizeColumn24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.filterTableColumn)}
              disabled={isSelectedTableLoaded}
            />
          </Tooltip>

          <Tooltip content={modalTooltip.lookup} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<DocumentTableSearch24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.lookup)}
              disabled={isSelectedTableLoaded}
            />
          </Tooltip>
        </BtnGroup> */}
        <BtnGroup>
          <Tooltip content={modalTooltip.viewRef} relationship="label">
            <Button
              size="large"
              appearance="subtle"
              icon={<TableLink24Regular />}
              onClick={() => !activeModalWindow && setActiveModalWindow(modalList.viewRef)}
              disabled={!isSelectedTableLoaded}
            />
          </Tooltip>
        </BtnGroup>
      </Inner>
    </Wrapper>
  );
};

export default TableToolbar;
