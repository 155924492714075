import React, { useContext } from "react";
import {
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  Button,
  Link,
} from "@fluentui/react-components";
import styled from "styled-components";
import { ClientContext } from "./App";
import { MessageType } from "../models/message";

const Wrapper = styled.div`
  .fui-MessageBar {
    padding-bottom: 12px;
  }
`;

const Message: React.FC<MessageType> = ({ title, desc, isMainBtn, mainBtnTxt, handleMainBtnClick }) => {
  const clientInfo = useContext(ClientContext);

  return (
    <Wrapper>
      <MessageBar layout="multiline" intent={"error"}>
        <MessageBarBody>
          <MessageBarTitle>{title}</MessageBarTitle>
          {desc} <Link href={`mailto:${clientInfo.contact}`}>contact us</Link> for further assistance.
        </MessageBarBody>
        {isMainBtn && (
          <MessageBarActions>
            <Button onClick={handleMainBtnClick}>{mainBtnTxt}</Button>
          </MessageBarActions>
        )}
      </MessageBar>
    </Wrapper>
  );
};

export default Message;
