export const optionItemMouseEnter = (id) => {
  const allItems = document.querySelectorAll(".dropdown-item");
  allItems.forEach((item) => item.classList.contains("hovered") && item.classList.remove("hovered"));
  const targetEl = document.getElementById(id);
  targetEl?.classList.add("hovered");
};

export const optionItemMouseLeave = (id) => {
  const targetEl = document.getElementById(id);
  targetEl?.classList.remove("hovered");
};
