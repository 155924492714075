/* eslint-disable */
/* global Excel console */
import { columnWidth, rptInst, rowColors } from "../config/excelConfig";
import { getReportStyle } from "../utils/apiCalls";
import {
  clearSheet, getColumnFormats, getColumnSizes, getData, getEPColumn,
  getLastUnusedRowIndex,
  getMetaData,
  loadHeader, setColumnFormatting, setColumnSizes, setDefaultFormatting,
  setDefaultInstantReportFormatting, setHeaderColor,
  setReportMetaData, setRowFormatting, setRowInteriorColor
} from "./excelHelper";




export const importInstantReportIntoExcel = async (report) => {
  if (report.length === 0) return;
  console.log(report);
  try {
    await Excel.run(async (context) => {
      OfficeExtension.config.debugInfo = true;
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const styles = JSON.parse( await OfficeRuntime.storage.getItem('instant-report-style'));
      const epKey = Object.keys(report[0]).includes('EP') ? 'EP' : 'ep';
      const dataArray = getData(report, epKey);
      const metaDataColumns = getMetaData(report, epKey);
      const columnSizes = getColumnSizes(sheet, report);
      const columnFormats = getColumnFormats(sheet, report);

      clearSheet(sheet);
      setDefaultInstantReportFormatting(sheet);
      setReportMetaData(sheet, "B1", metaDataColumns);
      let headerRange = loadHeader(sheet, report, epKey, styles);
      if (headerRange) setColumnSizes(columnSizes, headerRange, styles);

      if (dataArray.length === 0) {
        console.log(`Empty data`);
        return {status: "Empty" ,message: 'Empty data'};
      }

      let lastUnusedRowIndex = await getLastUnusedRowIndex(sheet, headerRange, context);
      const epColumns = getEPColumn(report, epKey, lastUnusedRowIndex);
      const dataRange = sheet.getUsedRange().getLastRow().getCell(1, 0).getResizedRange(dataArray.length-1, Object.values(dataArray[0]).length-1);

      if (Office.PlatformType.OfficeOnline) {
        const batchSize = 10000;
        for (let i = 0; i < dataArray.length; i += batchSize) {
          const batchData = dataArray.slice(i, i + batchSize);
          const batchRange = sheet.getUsedRange().getLastRow().getCell(1, 0).getResizedRange(batchData.length - 1, Object.values(batchData[0]).length - 1);
          batchRange.values = batchData;
          await context.sync();

          setDefaultFormatting(batchRange, styles);
          setRowFormatting(epColumns, batchRange, styles);
          setColumnSizes(columnSizes, batchRange, styles);
          setColumnFormatting(columnFormats, batchRange);
        }
      } else {
        dataRange.values = dataArray;
        setDefaultFormatting(dataRange, styles);
        setRowFormatting(epColumns, dataRange, styles);
        setColumnSizes(columnSizes, dataRange, styles);
        setColumnFormatting(columnFormats, dataRange);

      }


      setRowInteriorColor(epColumns, dataRange);
      setHeaderColor(headerRange, report, epKey);
      return context.sync();
    });
  } catch (e) {
    console.log(e);
    console.log(JSON.stringify(e));
    return e;
  }

}