/* eslint-disable */
let colors, defaultFormattingOptions, columnWidth, rowColors, colorIndexMap, styles = {};

try
{
  Office.onReady(info => {
    colors = {
      HEADER_GREEN: "#96F050",
      HEADER_BORDER: "#A2E4B8",
      FILL: "#d9e1f2",
      BLACK_FILL: "#000000",
      BLUE_FILL: "#bdd7ee",
      GREEN_FILL: "#c6efce",
      YELLOW_FILL: "#ffeb9c",
      ORANGE_FILL: "#ffd966",
      RED_FILL: "#ffc7ce",
      GREY_FILL: "#e8e8e8",
      MARK_FILL: "#D6F5EB",
      VERTICAL_SEPARATOR_FILL: "#000080",
    };


    columnWidth = {
      70: 371,
      50: 266.12,
      40: 214,
      35: 187.75,
      30: 161.54,
      25: 135.21,
      24: 129.89,
      22: 119.11,
      20: 108.81,
      18: 98,
      17: 92.89,
      16: 88,
      15: 82.24,
      14: 77.52,
      13: 72.21,
      12: 67,
      11: 62,
      10: 56.26,
      9: 51,
      8.71: 48, //wrong?
      8.43: 47.83,
      8: 46,
      7: 42.10,
      6: 35.21,
      5: 30,
      4.71: 28.8, //wrong?
      4: 25,
      3: 19.5,
      2: 14,
      "#SEP#": 4.74,
      "sep": 4.74,
      "separator": 4.74,
      "SEP": 4.74,
    };

    rowColors = {
      "#FILL#": "#D9E1F2",
      "#MARK_FILL#": "#EAF2F6",
      "#BLACK_LINE#": "#000000",
      "#BLACK_FILL#": "#000000",
      "#BLUE_LINE#": "#EAF2F6",
      "#BLUE_FILL#": "#EAF2F6",
      "#GREEN_LINE#": "InteriorColorIRCommonSubHeader",
      "#GREEN_FILL#": "InteriorColorIRCommonSubHeader",
      "#SUBHEADER_FILL#": "InteriorColorIRCommonSubHeader",
      "#SUBHEADER_LINE#": "InteriorColorIRCommonSubHeader",
      "#RP_COMMON_SUBHEADER#": "InteriorColorIRCommonSubHeader",
      "#YELLOW_LINE#": "InteriorColorIRCommonTitle",
      "#YELLOW_FILL#": "InteriorColorIRCommonTitle",
      "#TITLE_FILL#": "InteriorColorIRCommonTitle",
      "#TITLE_LINE#": "InteriorColorIRCommonTitle",
      "#RP_COMMON_TITLE#": "InteriorColorIRCommonTitle",
      "#ORANGE_LINE#": "#FFD966",
      "#ORANGE_FILL#": "#FFD966",
      "#RED_LINE#": "#FFC7CE",
      "#RED_FILL#": "#FFC7CE",
      "#GREY_LINE#": "#E8E8E8",
      "#GREY_FILL#": "#E8E8E8",
    };

    colorIndexMap = {
      1: "#000000",
      2: "#FFFFFF",
      3: "#FF0000",
      4: "#00FF00",
      5: "#0000FF",
      6: "#FFFF00",
      7: "#FF00FF",
      8: "#00FFFF",
      9: "#800000",
      10: "#008000",
      11: "#000080",
      12: "#808000",
      13: "#800080",
      14: "#008080",
      15: "#C0C0C0",
      16: "#808080",
      17: "#9999FF",
      18: "#993366",
      19: "#FFFFCC",
      20: "#CCFFFF",
      21: "#660066",
      22: "#FF8080",
      23: "#0066CC",
      24: "#CCCCFF",
      25: "#000080",
      26: "#FF00FF",
      27: "#FFFF00",
      28: "#00FFFF",
      29: "#800080",
      30: "#800000",
      31: "#008080",
      32: "#0000FF",
      33: "#00CCFF",
      34: "#CCFFFF",
      35: "#CCFFCC",
      36: "#FFFF99",
      37: "#99CCFF",
      38: "#FF99CC",
      39: "#CC99FF",
      40: "#FFCC99",
      41: "#3366FF",
      42: "#33CCCC",
      43: "#99CC00",
      44: "#FFCC00",
      45: "#FF9900",
      46: "#FF6600",
      47: "#666699",
      48: "#969696",
      49: "#003366",
      50: "#339966",
      51: "#003300",
      52: "#333300",
      53: "#993300",
      54: "#993366",
      55: "#333399",
      56: "#333333"
    };


    styles = {
      "InteriorColorTableHeader": "#BACDE8",
      "InteriorColorIRHeader": "#6ACDAC",
      "InteriorColorLookupFKField": "#EBEFF4",
      "InteriorColorFilterOrDummy": "#F7F1E8",
      "InteriorColorSeparator": "#3C6EB4",
      "InteriorColorMainSheet": "#FFFFFF",
      "InteriorColorInlineBd": "#465E81",
      "InteriorColorOutlineBd": "#1B2432",
      "InteriorColorHeaderTxt": "#000000",
      "InteriorColorBodyTxt": "#000000",
      "InteriorColorInfoTxt": "#9E4100",
      "InteriorColorEdit": "#BACDE8",
      "InteriorColorHyperlink": "#00419E",
      "InteriorColorIRCommonSubHeader": "#9DE1CB",
      "InteriorColorIRCommonTitle": "#D6F5EB",
      "InteriorColorIRCommonMark": "#EAF2F6",
      "InteriorColorIRSeparator": "#404040",
      "InteriorColorIRBorder": "#808080",
      "ContactFolderSharingDefaultTemplate": "# Tbenu"
    };


    defaultFormattingOptions = {
      TITLE: { format: {rowHeight: 30, font: {size: 20, bold: true}} },
      DESC: { format: {rowHeight: 16, font: {size: 12}} },
      INFO: { format: {rowHeight: 11, font: {size: 8}} },
      PARA: { format: {rowHeight: 11, font: {size: 8}} },
      HEADER: { format: {rowHeight: 30, font: { size: 8, bold: true}, verticalAlignment: Excel.VerticalAlignment.center, horizontalAlignment: Excel.HorizontalAlignment.center, wrapText: true}, color: 'InteriorColorIRHeader', borders: {all: {style: Excel.BorderLineStyle.continuous, color: 'InteriorColorIRBorder'}}},
      HEADERNOFILTER: { format: {rowHeight: 30, font: {size: 8, bold: true}, wrapText: true}, color: 'InteriorColorIRHeader', filter: false, borders: {all: {style: Excel.BorderLineStyle.continuous}}},
      FILL: { format: { fill: {color: "#D9E1F2"}} },
      LINE: { format: { rowHeight: 3}, content: 'clear'},
      MARK_FILL: { color: "InteriorColorIRCommonMark" },
      MARK_LINE : {color: "InteriorColorIRCommonMark", format: { rowHeight: 3}, content: 'clear'},
      CYAN_LINE: { format: {fill: {color: "#D9E1F2"}, rowHeight: 3}, content: 'clear' },
      CYAN_FILL: { format: {fill: {color: "#D9E1F2"} }},
      BLACK_LINE: { format: {fill: {color: "#000000"}, rowHeight: 3}, content: 'clear' },
      BLACK_FILL: { format: {fill: {color: "#000000"} }},
      BLUE_LINE: { format: {fill: {color: "#BDD7EE"}, rowHeight: 3}, content: 'clear' },
      BLUE_FILL: { format: {fill: {color: "#BDD7EE"}} },
      GREEN_LINE: { format: {fill: {color: "#C6EFCE"}, rowHeight: 3}, content: 'clear' },
      GREEN_FILL: { format: {fill: {color: "#C6EFCE"}} },
      SUBHEADER_FILL: { color: "InteriorColorIRCommonSubHeader" },
      SUBHEADER_LINE: { format:{rowHeight: 3}, color: "InteriorColorIRCommonSubHeader", content: 'clear' },
      RP_COMMON_SUBHEADER: { format: {fill:{color: "#9DE1CB"}} },
      YELLOW_LINE: { format: {fill: {color: "#FFEB9C"}, rowHeight: 3}, content: 'clear' },
      YELLOW_FILL: { format: {fill: {color: "#FFEB9C"}} },
      TITLE_FILL: { color: "InteriorColorIRCommonTitle" },
      TITLE_LINE: { format: {rowHeight: 3}, color: "InteriorColorIRCommonTitle", content: 'clear' },
      RP_COMMON_TITLE: { format: {fill: {color: "#D6F5EB"}} },
      ORANGE_LINE: { format: {fill: {color: "#FFD966"}, rowHeight: 3}, content: 'clear' },
      ORANGE_FILL: { format: {fill: {color: "#FFD966"}} },
      RED_LINE: { format: {fill: {color: "#FFC7CE"}, rowHeight: 3}, content: 'clear' },
      RED_FILL: { format: {fill:{color: "#FFC7CE"}} },
      GREY_LINE: { format: {fill: {color: "#E8E8E8"}, rowHeight: 3}, content: 'clear' },
      GREY_FILL: { format: {fill: {color: "#E8E8E8"}} },
      TEXT: {format: {numberFormat: [["@"]]}},
      SEP: {format: {fill: {color: "#404040"}, columnWidth: 3}, content: 'clear'}
    };

  });
} catch (e) {
  console.log(e);

}


export { colors, defaultFormattingOptions, columnWidth, rowColors, colorIndexMap, styles };