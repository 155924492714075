import {colorIndexMap, columnWidth as columnWidths} from "../config/excelConfig";
import {getReportStyle} from "../utils/apiCalls";

function setDefaultBorder(dataRange, styles) {
    const defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeTop];
    for (const border of defaultBorders) {
        dataRange.format.borders.getItem(border).style = Excel.BorderLineStyle.dot;
        dataRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
    }
}

//set column size and separator color
function setColumnSizes(columnSizes, headerRange, styles) {

    for (let i = 0;i<columnSizes.length;i++){
        if (columnWidths[columnSizes[i]] === undefined) {
            let automatedSize = 5.4 * columnSizes[i];
            console.log(`Couldn't find column width ${columnSizes[i]} for column number ${i} (0-indexed). Using automated column size: ${columnSizes[i]}->${automatedSize}`);
            headerRange.getCell(0, i).format.columnWidth = automatedSize;
            continue;
        }
        if (columnSizes[i].replace(" ", "").toLowerCase() === 'autofit') {
            headerRange.getCell(0, i).format.autofitColumns();
            continue;
        }
        if (columnSizes[i] === "#SEP#" || columnSizes[i] === "separator" || columnSizes[i] === "sep"){
            headerRange.getColumn(i).format.fill.color = styles['InteriorColorIRSeparator'];
            headerRange.getColumn(i).format.borders.getItem(Excel.BorderIndex.insideHorizontal).style = Excel.BorderLineStyle.none;
            headerRange.getColumn(i).clear(Excel.ClearApplyTo.contents);
            headerRange.getColumn(i).format.horizontalAlignment = Excel.HorizontalAlignment.left;
            headerRange.getColumn(i).format.indentLevel = 1;
            headerRange.getColumn(i).format.font.color = colorIndexMap[2];
        }
        headerRange.getCell(0, i).format.columnWidth = columnWidths[columnSizes[i]];
    }
}

function setDefaultFormatting(dataRange, styles) {
    setDefaultBorder(dataRange, styles);
    dataRange.format.rowHeight = 20;
    dataRange.format.verticalAlignment = Excel.VerticalAlignment.center;
    dataRange.format.horizontalAlignment = Excel.HorizontalAlignment.center;
    dataRange.format.font.size = 8;
    dataRange.format.wrapText = true;
    dataRange.format.fill.color = styles['InteriorColorMainSheet'];

}

function getFontBold(formatString) {
    const fontBoldRegex = /Font\s*\.\s*Bold\s*=\s*(True|False|true|false)/
    let result = formatString.match(fontBoldRegex);
    return result ? result[1].toLowerCase() === 'true' : null;
}

function getFontName(formatString) {
    const fontNameRegex = /Font\s*\.\s*Name\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontNameRegex);
    return result ? result[1].trim() : null;
}

function setFontStyle(formatString, row) {
    const fontStyleRegex = /Font\s*\.\s*FontStyle\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontStyleRegex);
    if (result){
        if (result[1].toLowerCase().replace(' ', '') === 'bolditalic') {
            row.format.font.bold = true;
            row.format.font.italic = true;
            return;
        }
        if (result[1].toLowerCase().replace(' ', '') === 'italic') {
            row.format.font.italic = true;
            return;
        }
        if (result[1].toLowerCase().replace(' ', '') === 'regular') {
            row.format.font.italic = false;
            row.format.font.bold = false;
        }
    }
}

function getFontSize(formatString) {
    const fontSizeRegex = /Font\s*\.\s*Size\s*=\s*([^#]+)(?:###|$)/;
    const result = formatString.match(fontSizeRegex);
    if (result) {
        return parseFloat(result[1].trim());
    }
    return null;
}

function getFontSubscript(formatString) {
    const subscriptRegex = /Font\s*\.\s*Subscript\s*=\s*(True|False|true|false)/;
    const result = formatString.match(subscriptRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontStrikethrough(formatString) {
    const strikethroughRegex = /Font\s*\.\s*Strikethrough\s*=\s*(True|False|true|false)/;
    const result = formatString.match(strikethroughRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontSuperscript(formatString) {
    const superscriptRegex = /Font\s*\.\s*Superscript\s*=\s*(True|False|true|false)/;
    const result = formatString.match(superscriptRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getFontItalic(formatString) {
    const italicRegex = /Font\s*\.\s*Italic\s*=\s*(True|False|true|false)/;
    const result = formatString.match(italicRegex);

    if (result) {
        return result[1].toLowerCase() === 'true';
    }
    return null;
}
function getTintAndShade(formatString) {
    const tintAndShadeRegex = /TintAndShade\s*=\s*(-?1(\.0)?|0?\.\d+|0|1)/;
    const result = formatString.match(tintAndShadeRegex);

    if (result) {
        const value = parseFloat(result[1]);
        return (value >= -1 && value <= 1) ? value : null;
    }
    return null;
}

function getFontUnderline(formatString) {
    const formattedString = formatString.replace(/\s+/g, '').toLowerCase();

    if (formattedString.includes("font.underline=xlunderlinestyledouble")) {
        return Excel.RangeUnderlineStyle.double;
    }
    if (formattedString.includes("font.underline=xlunderlinestyledoubleaccounting")) {
        return Excel.RangeUnderlineStyle.doubleAccountant;
    }
    if (formattedString.includes("font.underline=xlunderlinestylenone")) {
        return Excel.RangeUnderlineStyle.none;
    }
    if (formattedString.includes("font.underline=xlunderlinestylesingle")) {
        return Excel.RangeUnderlineStyle.single;
    }
    if (formattedString.includes("font.underline=xlunderlinestylesingleaccounting")) {
        return Excel.RangeUnderlineStyle.singleAccountant;
    }

    return null;
}

function getFontColor(formatString) {
    const rgbRegex = /Font\s*\.\s*Color\s*=\s*RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i;
    const result = formatString.match(rgbRegex);
    return rgbToHex(result);
}

function getWrapText(formatString) {
    const wrapTextRegex = /WrapText\s*=\s*(True|False|true|false)/i;
    const result = formatString.match(wrapTextRegex);
    if (result) {
        if (result[1]) return result[1].toLowerCase() === 'true';
    }
    return null;
}

function getRGBInteriorColor(formatString) {
    const colorRegex = /Interior\s*\.\s*Color\s*=\s*RGB\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)(?:###|$)/i;
    const result = formatString.match(colorRegex);
    return result ? {
        r: parseInt(result[1], 10),
        g: parseInt(result[2], 10),
        b: parseInt(result[3], 10)
    } : null;
}

function getInteriorColor(formatString) {
    const rgbRegex = /Interior\s*\.\s*Color\s*=\s*RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i;
    const result = formatString.match(rgbRegex);

    return rgbToHex(result);
}

function rgbToHex(result) {
    if (result) {
        const red = parseInt(result[1]);
        const green = parseInt(result[2]);
        const blue = parseInt(result[3]);

        return `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).toUpperCase()}`;
    }
    return null;
}


function setFont(epColumn, dataRange) {
    let row = dataRange.getRow(epColumn.index);
    if (!epColumn.format.includes('Font'))
        return;
    let fontBold = getFontBold(epColumn.format);
    let fontName = getFontName(epColumn.format);
    let fontSize = getFontSize(epColumn.format);
    let fontSubscript = getFontSubscript(epColumn.format);
    let fontStrikethrough = getFontStrikethrough(epColumn.format);
    let fontSuperscript = getFontSuperscript(epColumn.format);
    let fontItalic = getFontItalic(epColumn.format);
    let fontTintAndShade = getTintAndShade(epColumn.format);
    let fontUnderlineStyle = getFontUnderline(epColumn.format);
    let fontColor = getFontColor(epColumn.format);

    setFontStyle(epColumn.format, row);

    if (fontBold === true || fontBold === false) row.format.font.bold = fontBold;
    if (fontName) row.format.font.name = fontName;
    if (fontSize) row.format.font.size = fontSize;
    if (fontSubscript === true || fontSubscript === false) row.format.font.subscript = fontSubscript;
    if (fontSuperscript === true || fontSuperscript === false) row.format.font.superscript = fontSuperscript;
    if (fontStrikethrough === true || fontStrikethrough === false) row.format.font.strikethrough = fontStrikethrough;
    if (fontItalic === true || fontItalic === false) row.format.font.italic = fontItalic;
    if (fontTintAndShade => -1 && fontTintAndShade <= 1) row.format.font.tintAndShade = fontTintAndShade;
    if (fontUnderlineStyle) row.format.font.underline = fontUnderlineStyle;
    if (fontColor) row.format.font.color = fontColor;


}

function getIndentLevel(formatString) {
    const indentLevelRegex = /IndentLevel\s*=\s*(\d+)(?:###|$)/;
    const result = formatString.match(indentLevelRegex);
    return result ? parseInt(result[1], 10) : null;
}


function getShrinkToFit(formatString) {
    const shrinkToFitRegex = /ShrinkToFit\s*=\s*(true|false|True|False)(?:###|$)/;
    const result = formatString.match(shrinkToFitRegex);
    return result ? result[1].toLowerCase() === "true" : null;
}


function parseExcelBorderFromVBA(vbaBorderName) {
    switch (vbaBorderName){
        case 'xlLineStyleNone':
            return Excel.BorderLineStyle.none;
        case 'xlContinuous':
            return Excel.BorderLineStyle.continuous;
        case 'xlDash':
            return Excel.BorderLineStyle.dash;
        case 'xlDashDot':
            return Excel.BorderLineStyle.dashDot;
        case 'xlDashDotDot':
            return Excel.BorderLineStyle.dashDotDot;
        case 'xlDot':
            return Excel.BorderLineStyle.dot;
        case 'xlDouble':
            return Excel.BorderLineStyle.double;
        case 'xlSlantDashDot':
            return Excel.BorderLineStyle.slantDashDot;
        default:
            console.log("Couldn't find border!")
            return Excel.BorderLineStyle.none;
    }
}

function setRowColor(epColumn, dataRange, styles) {
    let row = dataRange.getRow(epColumn.index);
    let color;

    switch (true) {
        case (epColumn.format.startsWith("#LINE#") || epColumn.format.startsWith("#FILL#")):
            color = "#D9E1F2";
            break;
        case epColumn.format.includes("#BLACK_LINE#") || epColumn.format.includes("#BLACK_FILL#"):
            color = "#000000";
            break;
        case epColumn.format.includes("#BLUE_LINE#") || epColumn.format.includes("#BLUE_FILL#"):
            color = "#BDD7EE";
            break;
        case epColumn.format.includes("#GREEN_LINE#") || epColumn.format.includes("#GREEN_FILL#"):
            color = "#C6EFCE";
            break;
        case epColumn.format.includes("#YELLOW_LINE#") || epColumn.format.includes("#YELLOW_FILL#"):
            color = "#FFE79C";
            break;
        case epColumn.format.includes("#ORANGE_LINE#") || epColumn.format.includes("#ORANGE_FILL#"):
            color = "#FFD966";
            break;
        case epColumn.format.includes("#RED_LINE#") || epColumn.format.includes("#RED_FILL#"):
            color = "#FFC7CE";
            break;
        case epColumn.format.includes("#GREY_LINE#") || epColumn.format.includes("#GREY_FILL#"):
            color = "#E8E8E8";
            break;
        case epColumn.format.includes("#MARK_LINE#") || epColumn.format.includes("#MARK_FILL#"):
            color = styles['InteriorColorIRCommonMark'];
            break;
        case epColumn.format.includes("#SUBHEADER_LINE#") || epColumn.format.includes("#SUBHEADER_FILL#"):
            color = styles['InteriorColorIRCommonSubHeader'];
            break;
        case epColumn.format.includes("#TITLE_LINE#") || epColumn.format.includes("#TITLE_FILL#"):
            color = styles['InteriorColorIRCommonTitle']
            break;
        default:
            color = null;
    }

    if (epColumn.format.includes("LINE")){
        row.format.rowHeight = 3;
        row.format.borders.getItem(Excel.BorderIndex.edgeLeft).style = Excel.BorderLineStyle.none;
        row.format.borders.getItem(Excel.BorderIndex.edgeRight).style = Excel.BorderLineStyle.none;
        row.clear(Excel.ClearApplyTo.contents);
    }

    if (epColumn.format.startsWith("RGB")){ //todo: ?
        const interiorColorRegex = /RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/;
        let result = epColumn.format.match(interiorColorRegex);
        if (result){
            const [red, green, blue] = [parseInt(result[1]), parseInt(result[2]), parseInt(result[3])];
            const toHex = (value) => value.toString(16).padStart(2, '0').toUpperCase();
            color = `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
        }
    }


    if (color) {
        row.format.fill.color = color;
    }
}

function setAlignment(item, dataRange) {
    let horizontalAlignment = getHorizontalAlignment(item.format);
    let verticalAlignment = getVerticalAlignment(item.format);
    let row = dataRange.getRow(item.index);
    if (horizontalAlignment) row.format.horizontalAlignment = horizontalAlignment;
    if (verticalAlignment) row.format.verticalAlignment = verticalAlignment;
}

function getHorizontalAlignment(formatString) {
    const alignmentRegex = /HorizontalAlignment\s*=\s*(xlCenter|xlRight|xlLeft)(?:###|$)/;
    const result = formatString.match(alignmentRegex);
    if (result)
        switch (result[1].replace(" ", "").toLowerCase()) {
            case "xlleft":
                return Excel.HorizontalAlignment.left;
            case "xlright":
                return Excel.HorizontalAlignment.right;
            case "xlcenter":
                return Excel.HorizontalAlignment.center;
            case "xldistributed":
                return Excel.HorizontalAlignment.distributed;
            case "xljustify":
                return Excel.HorizontalAlignment.justify;
            case "xlgeneral":
                return Excel.HorizontalAlignment.general
            default:
                console.log(`Couldn't find horizontal alignment ${result[1].replace(" ", "").toLowerCase()}`);
                return Excel.HorizontalAlignment.center;
        }
    return null;
}

function getVerticalAlignment(formatString) {
    const alignmentRegex = /VerticalAlignment\s*=\s*(xlTop|xlBottom|xlCenter|xlDistributed|xlJustify)(?:###|$)/;
    const result = formatString.match(alignmentRegex);
    if (result)
        switch (result[1].replace(" ", "").toLowerCase()) {
            case "xltop":
                return Excel.VerticalAlignment.top;
            case "xlbottom":
                return Excel.VerticalAlignment.bottom;
            case "xlcenter":
                return Excel.VerticalAlignment.center;
            case "xldistributed":
                return Excel.VerticalAlignment.distributed;
            case "xljustify":
                return Excel.VerticalAlignment.justify;
            default:
                console.log(`Couldn't find vertical alignment ${result[1].replace(" ", "").toLowerCase()}`);
                return Excel.VerticalAlignment.center;
        }
    return null;
}

function getRowHeight(formatString) {
    const rowHeightRegex = /RowHeight\s*=\s*(\d+)(?:###|$)/;
    const result = formatString.match(rowHeightRegex);
    return result ? parseInt(result[1], 10) : null;
}

function getClearFormats(formatString) {
    return formatString.replace(" ", "").toLowerCase().includes(".clearformats");
}

function setRowProperties(epColumn, dataRange) {
    let row = dataRange.getRow(epColumn.index);
    let rowHeight = getRowHeight(epColumn.format);
    let wrapText = getWrapText(epColumn.format);
    let shrinkToFit = getShrinkToFit(epColumn.format);
    let indentLevel = getIndentLevel(epColumn.format);

    if (rowHeight) row.format.rowHeight = rowHeight;
    if (getClearFormats(epColumn.format)) row.clear(Excel.ClearApplyTo.formats);
    if (wrapText === true || wrapText === false) row.format.wrapText = wrapText;
    if (shrinkToFit === true || shrinkToFit === false) row.format.shrinkToFit = shrinkToFit;
    if (indentLevel >= 0 && indentLevel <= 250 && indentLevel) {
        row.format.horizontalAlignment = Excel.HorizontalAlignment.left;
        row.format.indentLevel = indentLevel;
    }

}

function findRowByEp(data, searchString) {
    const epKey = Object.keys(data[0]).includes('EP') ? 'EP' : 'ep';
    const row = data.find(item => item[epKey] && item[epKey].startsWith(searchString));

    if (row) {
        const rowKeys = Object.keys(row);
        return row[rowKeys[1]];
    }
    return null;
}

function setColumnFontFormatting(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);

    let fontBold = getFontBold(formatRowValue.value);
    let fontName = getFontName(formatRowValue.value);
    let fontSize = getFontSize(formatRowValue.value);
    let fontSubscript = getFontSubscript(formatRowValue.value);
    let fontStrikethrough = getFontStrikethrough(formatRowValue.value);
    let fontSuperscript = getFontSuperscript(formatRowValue.value);
    let fontItalic = getFontItalic(formatRowValue.value);
    let fontTintAndShade = getTintAndShade(formatRowValue.value);
    let fontUnderlineStyle = getFontUnderline(formatRowValue.value);
    let fontColor = getFontColor(formatRowValue.value);

    if (fontBold === true || fontBold === false) column.format.font.bold = fontBold;
    if (fontName) column.format.font.name = fontName;
    if (fontSize) column.format.font.size = fontSize;
    if (fontSubscript === true || fontSubscript === false) column.format.font.subscript = fontSubscript;
    if (fontSuperscript === true || fontSuperscript === false) column.format.font.superscript = fontSuperscript;
    if (fontStrikethrough === true || fontStrikethrough === false) column.format.font.strikethrough = fontStrikethrough;
    if (fontItalic === true || fontItalic === false) column.format.font.italic = fontItalic;
    if (fontTintAndShade => -1 && fontTintAndShade <= 1) column.format.font.tintAndShade = fontTintAndShade;
    if (fontUnderlineStyle) column.format.font.underline = fontUnderlineStyle;
    if (fontColor) column.format.font.color = fontColor;
    column.untrack();
}

function setColumnAlignment(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);
    let verticalAlignment = getVerticalAlignment(formatRowValue.value);
    let horizontalAlignment = getHorizontalAlignment(formatRowValue.value);

    if (verticalAlignment) column.format.verticalAlignment = verticalAlignment;
    if (horizontalAlignment) column.format.horizontalAlignment = horizontalAlignment;
    column.untrack();
}

function setColumnProperties(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);

    let wrapText = getWrapText(formatRowValue.value);
    let shrinkToFit = getShrinkToFit(formatRowValue.value);
    let indentLevel = getIndentLevel(formatRowValue.value);
    if (wrapText === true || wrapText === false) column.format.wrapText = wrapText;
    if (shrinkToFit === true || shrinkToFit === false) column.format.shrinkToFit = shrinkToFit;
    if (indentLevel >= 0 && indentLevel <= 250 && indentLevel) {
        column.format.horizontalAlignment = Excel.HorizontalAlignment.left;
        column.format.indentLevel = indentLevel;
    }
    column.untrack();
}

function getNumberFormat(formatString) {
    const numberFormatRegex = /NumberFormat=(.*)(#{3})|NumberFormat=(.*)/i;
    const result = formatString.match(numberFormatRegex);
    if (!result) return null;
    if (result[3] === undefined) {
        return result[1];
    }
    if (result[3]) {
        return result[3];
    }
    return null;
}


function setColumnNumberFormat(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);
    let numberFormat = getNumberFormat(formatRowValue.value);
    if (numberFormat) column.numberFormat = numberFormat;
}

function setColumnColor(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);
    let color = getInteriorColor(formatRowValue.value);
    if (color) column.format.fill.color = color;
}

function getBorderLineStyle(formatString) {
    const generalBorderStyleRegex = /Borders(?:\((xlEdgeTop|xlEdgeBottom|xlEdgeLeft|xlEdgeRight|xlDiagonalDown|xlDiagonalUp|xlInsideHorizontal|xlInsideVertical)\))?\s*\.LineStyle\s*=\s*(xlLineStyleNone|xlLineStyleContinuous|xlLineStyleDash|xlLineStyleDashDot|xlLineStyleDashDotDot|xlLineStyleDot|xlLineStyleDouble|xlLineStyleSlantDashDot)/i;
    const generalResult = formatString.match(generalBorderStyleRegex);
    if (formatString.match("#LINE#")){
        return Excel.BorderLineStyle.none;
    }
    if (generalResult) {
        if (generalResult[2] !== undefined){
            return parseExcelBorderFromVBA(generalResult[2]);
        }
        return Excel.BorderLineStyle[generalResult[2].replace(/.*=\s*/, '').toLowerCase()];
    }

    return null;
}

function getBorderColor(formatString) {
    const borderColorRegex = /Borders\s*\.\s*Color\s*=\s*RGB\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i;
    const result = formatString.match(borderColorRegex);
    return result ? `#${((1 << 24) + (parseInt(result[1], 10) << 16) + (parseInt(result[2], 10) << 8) + parseInt(result[3], 10)).toString(16).slice(1).toUpperCase()}` : null;
}

function getBorderWeight(formatString) {
    const borderWeightRegex = /Borders\s*\.\s*Weight\s*=\s*(xlHairline|xlThin|xlMedium|xlThick)(?:###|$)/i;
    const result = formatString.match(borderWeightRegex);
    if (result)
        switch (result[1].replace(" ", "").toLowerCase()){
            case "xlhairline":
                return Excel.BorderWeight.hairline;
            case "xlthin":
                return Excel.BorderWeight.thin;
            case "xlmedium":
                return Excel.BorderWeight.medium;
            case "xlthick":
                return Excel.BorderWeight.thick;
            default:
                return null;
        }
    return null;
}

function setBorder(item, dataRange, epColumns, epIndex, lastIndex) {
    const excelBorder = getBorderLineStyle(item.format);
    const excelBorderColor = getBorderColor(item.format);
    const excelBorderWeight = getBorderWeight(item.format);
    if (excelBorder){
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft];
        for (const border of defaultBorders) {
            if (item.index === 0){
                dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeTop).style = excelBorder;
            }
            //top border check
            if (epColumns[epIndex-1]) {
                if (epColumns[epIndex-1].index+1 === item.index) {
                    let topBorder = getBorderLineStyle(epColumns[epIndex-1].format); //todo
                    if (topBorder) dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeTop).style = excelBorder;
                }
            }

            //bottom border check
            if (epColumns[epIndex+1]) {
                if (epColumns[epIndex+1].index-1 === item.index) {
                    let bottomBorder = getBorderLineStyle(epColumns[epIndex+1].format);
                    if (bottomBorder) dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeBottom).style = excelBorder;
                }
            }
            if (item.index === lastIndex){
                dataRange.getRow(item.index).format.borders.getItem(Excel.BorderIndex.edgeBottom).style = excelBorder;
            }
            dataRange.getRow(item.index).format.borders.getItem(border).style = excelBorder;
        }
        // return excelBorder;
    }
    if (excelBorderColor){
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeTop];
        for (const border of defaultBorders) {
            dataRange.getRow(item.index).format.borders.getItem(border).color = excelBorderColor;
        }
    }
    if (excelBorderWeight) {
        let defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.insideHorizontal, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeTop];
        for (const border of defaultBorders) {
            dataRange.getRow(item.index).format.borders.getItem(border).weight = excelBorderWeight;
        }

    }
}

function clearSheet(sheet) {
    sheet.getRange().clear(Excel.ClearApplyTo.all);
    sheet.getRange().format.rowHeight = 15;
    sheet.getRange().format.columnWidth = 47.83;
    sheet.freezePanes.unfreeze();
}

function setDefaultInstantReportFormatting(sheet) {
    sheet.showGridlines = false;
    sheet.getRange("A1").format.columnWidth = columnWidths[3];
}

function setTitle(sheet, range, data) {
    const rowValue = findRowByEp(data, '#TITLE#');
    if (rowValue === null) {
        console.log(`Couldn't find TITLE!`);
    }

    const titleRange = sheet.getRange(range);
    titleRange.format.rowHeight = 30;
    titleRange.format.font.size = 20;
    titleRange.format.font.bold = true;
    titleRange.format.verticalAlignment = Excel.VerticalAlignment.center;
    titleRange.values = [[rowValue]];
    return titleRange;
}

function setTitleFormatting(sheet, titleRange, data) {
    const epKey = Object.keys(data[0]).includes('EP') ? 'EP' : 'ep';
    let titleFormatValue = data[0][epKey];
    if (titleFormatValue.includes("##VBACode##")){
        let fontUnderlineStyle = getFontUnderline(titleFormatValue);
        if (fontUnderlineStyle) titleRange.format.font.underline = fontUnderlineStyle;
    }
}

function setDesc(sheet, data) {
    const rowValue = findRowByEp(data, '#DESC#');
    if (rowValue === null) {
        console.log(`Couldn't find DESC!`);
        return;
    }

    const descRange = sheet.getUsedRange().getLastRow().getResizedRange(1, 0).getLastRow();
    descRange.format.rowHeight = 16;
    descRange.format.font.size = 12;
    descRange.format.verticalAlignment = Excel.VerticalAlignment.center;
    descRange.values = [[rowValue]];
}

function setInfo(sheet, data) {
    const rowValue = findRowByEp(data, '#INFO#');
    if (rowValue === null) {
        console.log(`Couldn't find INFO!`);
        return;
    }

    const infoRange = sheet.getUsedRange().getLastRow().getResizedRange(1, 0).getLastRow();
    infoRange.format.rowHeight = 11;
    infoRange.format.font.size = 8;
    infoRange.format.verticalAlignment = Excel.VerticalAlignment.center;
    infoRange.values = [[rowValue]]
}

function setPara(sheet, data) {
    const rowValue = data.find(item =>
        (item.ep && item.ep.startsWith('#PARA#')) ||
        (item.EP && item.EP.startsWith('#PARA#'))
    );

    if (!rowValue) {
        console.log(`Couldn't find PARA!`);
        return;
    }

    let paraRange = sheet.getUsedRange().getLastRow().getResizedRange(1, 0).getLastRow();

    Object.keys(rowValue).forEach((key) => {
        const value = rowValue[key];
        if (value !== '#PARA#' && value !== '' && value !== ' ' && key !== 'index') {
            paraRange.format.rowHeight = 11;
            paraRange.format.font.size = 8;
            paraRange.format.verticalAlignment = Excel.VerticalAlignment.center;

            paraRange.values = [[value]];

            paraRange = paraRange.getResizedRange(0, 1).getLastCell();
        }
    });
}

function loadHeader(sheet, data, epKey, styles) {
    const headerRowValue = data.find(item =>
        (item[epKey] && item[epKey].startsWith('#HEADER#')) ||
        (item[epKey] && item[epKey].startsWith('#HEADERNOFILTER#'))
    );
    if (!headerRowValue) {
        console.log(`Couldn't find HEADER!`);
        return;
    }

    const  { ep, EP, ...headerData } = headerRowValue;
    //SET HEADER DATA
    sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, Object.values(headerData).length - 1).getLastRow().values = [Object.values(headerData)];

    let headerRange = sheet.getUsedRange().getLastRow();
    headerRange.format.rowHeight = 30;
    headerRange.format.wrapText = true;
    headerRange.format.font.bold = true;
    headerRange.format.font.size = 8;
    headerRange.format.verticalAlignment = Excel.VerticalAlignment.center;
    headerRange.format.horizontalAlignment = Excel.HorizontalAlignment.center;

    const defaultBorders = [Excel.BorderIndex.insideVertical, Excel.BorderIndex.edgeRight, Excel.BorderIndex.edgeLeft, Excel.BorderIndex.edgeBottom, Excel.BorderIndex.edgeTop];
    for (const border of defaultBorders) {
        headerRange.format.borders.getItem(border).style = Excel.BorderLineStyle.continuous;
        headerRange.format.borders.getItem(border).color = styles['InteriorColorIRBorder'];
    }

    headerRange.format.fill.color = styles['InteriorColorIRHeader'];
    sheet.autoFilter.apply(headerRange);

    //SET VERTICAL SEPARATORS __
    for (let i = 0;i<Object.keys(headerData).length;i++){
        if (Object.values(headerData)[i] === "__") {
            headerRange.getCell(0, i).format.fill.color = styles['InteriorColorIRSeparator'];
            headerRange.getCell(0, i).values = [['']];
        }
    }

    return headerRange;
}

function setHeaderColor(headerRange, data, epKey) {
    if (!headerRange) return;
    const formatRowValue = data.find(item => (item[epKey] && item[epKey].startsWith('#FORMAT#')));
    if (!formatRowValue) {
        console.log(`Couldn't find FORMAT!`);
        return;
    }
    let formatKeys = Object.keys(formatRowValue);
    for (let i=1;i<formatKeys.length;i++){
        let interiorColor = getRGBInteriorColor(formatRowValue[formatKeys[i]]);
        if (interiorColor) {
            let rgbColor = {};
            rgbColor.r = Math.round(Math.pow(Math.pow(interiorColor.r, 2.2) * 0.85, 1 / 2.2));
            rgbColor.g = Math.round(Math.pow(Math.pow(interiorColor.g, 2.2) * 0.85, 1 / 2.2));
            rgbColor.b = Math.round(Math.pow(Math.pow(interiorColor.b, 2.2) * 0.85, 1 / 2.2));
            headerRange.getColumn(i-1).format.fill.color = `#${((1 << 24) + (rgbColor.r << 16) + (rgbColor.g << 8) + rgbColor.b).toString(16).slice(1).toUpperCase()}`;
        }

    }
}

function getColumnSizes(sheet, data) {
    const rowValue = data.find(item =>
        (item['ep'] && item['ep'].startsWith("#COLSIZE#")) ||
        (item['EP'] && item['EP'].startsWith("#COLSIZE#"))
    );
    return Object.values(rowValue).slice(1, Object.values(rowValue).length);
}

function getColumnFormats(sheet, data) {
    const rowValue = data.find(item =>
        (item['ep'] && item['ep'].startsWith("#FORMAT#")) ||
        (item['EP'] && item['EP'].startsWith("#FORMAT#"))
    );
    return Object.values(rowValue).slice(1, Object.values(rowValue).length);
}


function setReportMetaData(sheet, range, data) {
    let titleRange = setTitle(sheet, range, data);
    setTitleFormatting(sheet, titleRange, data);
    setDesc(sheet, data);
    setInfo(sheet, data);
    setPara(sheet, data);
}

function getMetaData(report, epKey) {
    return report
        .map((obj, index) => {
            if (obj[epKey] !== null) {
                const newObj = { ...obj, index };
                return Object.fromEntries(Object.entries(newObj));
            }
        })
        .filter(Boolean);
}

function getEPColumn(report, epKey ) {
    const skipEpColumns = ["#TITLE#", "#DESC#", "#INFO#", "#PARA#", "#COLSIZE#", "#FORMAT#", "#HEADER#", "#HEADERNOFILTER#"];
    let epIndex = 0;
    let epColumns = [];
    for (const item of report) {
        let incremented = false;
        const value = item[epKey];
        if (value === null) {
            epIndex++;
            incremented = true;
            continue;
        }
        let skip = skipEpColumns.some(v => { if (value===null) return true; if(value.toString().startsWith(v)) return true;});
        if (skip) continue;
        if (value === "") {
            epIndex++;
            incremented = true;
            continue;
        }

        epColumns.push({format: value, index: epIndex});
        if (!incremented) epIndex++;
    }
    return epColumns;
}

function getData(report, epKey) {
    const skipEpColumns = ["#TITLE#", "#DESC#", "#INFO#", "#PARA#", "#COLSIZE#", "#FORMAT#", "#HEADER#", "#HEADERNOFILTER#"];

    return report
        .filter(item => {
            const epValue = item[epKey];
            return epValue === null ||
                !skipEpColumns.some(skipValue => epValue.includes(skipValue));
        })
        .map(({ [epKey]: _, ...rest }) => Object.values(rest));
}


async function getLastUnusedRowIndex(sheet, headerRange = null, context) {
    let lastUnusedRowIndex;

    if (headerRange) {
        headerRange.load("rowIndex");
        headerRange.load("rowCount");
        await context.sync();
        lastUnusedRowIndex = headerRange.rowIndex + 1;
        sheet.freezePanes.freezeRows(headerRange.rowIndex + 1);
    } else {
        let usedRangeLastRow = sheet.getUsedRange().getLastRow().getCell(0, 0).getResizedRange(1, 0).getLastRow();
        usedRangeLastRow.load("rowIndex");
        await context.sync();
        lastUnusedRowIndex = usedRangeLastRow.rowIndex;
    }

    return lastUnusedRowIndex;
}


function setRowInteriorColor(epColumns, dataRange) {
    for (const item of epColumns)
    {
        if (item.format === null) continue;
        let interiorColor = getInteriorColor(item.format);
        if (interiorColor) {
            dataRange.getRow(item.index).format.fill.color = interiorColor;
        }
    }
}


function setRowFormatting(epColumns, dataRange, styles) {
    if (epColumns.length === 0) {
        console.log('Empty row formatting');
        return;
    }
    let lastIndex = epColumns[epColumns.length - 1].index;

    for (let i = 0; i < epColumns.length; i++) {
        const item = epColumns[i];
        setRowColor(item, dataRange, styles);
        setFont(item, dataRange);
        setBorder(item, dataRange, epColumns, i, lastIndex);
        setAlignment(item, dataRange);
        setRowProperties(item, dataRange);
    }
}


function setVBAColumnFormatting(formatRowValue, dataRange) {
    setColumnFontFormatting(formatRowValue, dataRange);
    setColumnAlignment(formatRowValue, dataRange);
    setColumnProperties(formatRowValue, dataRange);
    setColumnColor(formatRowValue, dataRange);
}

function setPreDefinedColumnFormatting(formatRowValue, dataRange) {
    let column = dataRange.getColumn(formatRowValue.index);
    let numberFormat = formatRowValue.value;
    switch (numberFormat) {
        case "":
            numberFormat = "@";
            break;
        case "Text":
            numberFormat = "@";
            break;
        case "Memo":
            numberFormat = Excel.NumberFormatCategory.general;
            column.format.font.size = 8;
            column.format.font.name = "Arial Narrow";
            break;
        case "Center":
            column.format.horizontalAlignment = Excel.HorizontalAlignment.center;
            break;
        case "Bold":
            column.format.font.bold = true;
            break;
        case "Bold_blue":
            column.clear(Excel.ClearApplyTo.removeHyperlinks);
            column.format.font.bold = true;
            column.format.font.color = colorIndexMap[32];
            break;
        case "TimeStamp":
            //dd.mm.yyyy hh:mm:ss
            numberFormat = "dd.mm.yyyy hh:mm:ss";
            column.format.font.name = "Arial";
            column.format.font.size = 8;
            break;
        case "TimeStamp_grey":
            numberFormat = "dd.mm.yyyy hh:mm:ss";
            column.format.font.name = "Arial";
            column.format.font.size = 8;
            column.format.font.color = colorIndexMap[16];
            break;
        case "Date":
            numberFormat = "dd.mm.yyyy";
            break;
        case "Grey_small":
            column.format.font.name = "Arial";
            column.format.font.size = 8;
            column.format.font.color = colorIndexMap[16];
            break;
        case "Currency_2":
            numberFormat = `#,##0.00_ ;[Red]-#,##0.00`;
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            break;
        case "Currency_3":
            numberFormat = `#,##0.00_ ;[Red]-#,##0.00`;
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            break;
        case "Decimals_2":
            numberFormat = `#,##0.00_ ;[Red]-#,##0.00`;
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            break;
        case "Percent":
            numberFormat = "0.00%;[Red]-0.00%";
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            break;
        case "FXRate":
            column.numberFormatLocal = "0,00000000";
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            column.format.font.color = colorIndexMap[51];
            break;
        case "Multiple":
            column.numberFormatLocal = "0,00x";
            column.format.font.name = "Arial";
            column.format.font.size = 10;
            break;
        case "Disabled":
            column.format.fill.pattern = Excel.FillPattern.lightHorizontal;
            column.format.fill.patternColor = colorIndexMap[15];
            column.format.font.color = colorIndexMap[16];
            break;
        default:
            console.log(`Couldn't find number format for ${formatRowValue.value}`);
    }
    if (numberFormat) column.numberFormat = numberFormat;


}

function setColumnFormatting(formatRows, dataRange) {
    for (let i = 0; i < formatRows.length; i++) {
        if (formatRows[i] === undefined || formatRows[i] === '' || formatRows[i] === null) continue;
        let formatRow = formatRows[i];

        let formatRowValue = { value: formatRow.valueOf(), index: i };

        if (formatRow.startsWith("##VBACode##")) {
            setVBAColumnFormatting(formatRowValue, dataRange);
            setColumnNumberFormat(formatRowValue, dataRange);
        } else {
            setPreDefinedColumnFormatting(formatRowValue, dataRange);
        }
    }
}

function parseVBAHEX(vbaHexString) {
    if (typeof vbaHexString !== "string") return;
    let modified = vbaHexString.substring(2);
    return "#" + modified.substring(4, 6) + modified.substring(2, 4) + modified.substring(0, 2);
}

async function getDefaultStyle() {
    let resp = await getReportStyle();
    let mappedResp = {};
    for (let i = 0; i < resp[0].length; i++) {
        mappedResp[resp[0][i]["PropertyName"]] = parseVBAHEX(resp[0][i]["StrPropertyValue"]);
    }
    return mappedResp;
}



export {getBorderColor, getDefaultStyle, getColumnSizes, setRowFormatting, setHeaderColor, setColumnFormatting, setRowInteriorColor, getColumnFormats, getLastUnusedRowIndex, loadHeader, rgbToHex, getBorderWeight, getData, getMetaData, setReportMetaData, setInfo, setPara, setDesc, getEPColumn, setDefaultFormatting, setTitle, setDefaultInstantReportFormatting, clearSheet, setBorder, setColumnFontFormatting, setColumnAlignment, setColumnProperties, getClearFormats, getNumberFormat, getRowHeight, setColumnNumberFormat, setColumnColor, findRowByEp, getIndentLevel, getVerticalAlignment, getHorizontalAlignment, getInteriorColor, getRGBInteriorColor,setRowProperties, setAlignment, setDefaultBorder, getShrinkToFit, getWrapText, parseExcelBorderFromVBA, setColumnSizes, setFont, setRowColor, getFontColor, getFontBold, getFontItalic, getFontName, getFontSize, setFontStyle, getFontStrikethrough, getFontSubscript, getTintAndShade, getFontUnderline, getFontSuperscript}