import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  overflow-y: auto;
  display: grid;

  /* Apply thin scrollbars across WebKit browsers */
  &::-webkit-scrollbar {
    width: 6px; /* Thin width for the scrollbar */
  }

  /* Track (background of scrollbar) */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light background for track */
    border-radius: 10px;
  }

  /* Handle (the actual scrolling part) */
  &::-webkit-scrollbar-thumb {
    background: #888; /* Darker color for the handle */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
  }
`;

export const Inner = styled.div`
  display: grid;
  margin: 30px 20px 64px;

  &.instant-report {
    grid-template-rows: 1fr max-content;
  }

  &.table-window {
    grid-template-rows: max-content 1fr max-content;
  }

  .divider {
    margin: 16px 0;
  }
`;

export const SelectBtnWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: end;
  gap: 10px;

  button {
    margin-bottom: 13px;
  }
`;

export const FieldsWrapper = styled.div`
  width: 100%;
`;

export const BtnWrapper = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    width: 102px;
    height: 32px;
    cursor: "not-allowed";
  }
`;
