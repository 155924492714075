import React from "react";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 38px;
  width: 100%;

  .fui-SkeletonItem {
    height: 38px;
  }
`;

const SkeletonLoader = () => (
  <Wrapper>
    <Skeleton>
      <SkeletonItem />
    </Skeleton>
  </Wrapper>
);

export default SkeletonLoader;
