/* eslint-disable */
import React from "react";
import styled from "styled-components";
import { Col } from "./Fields/styles.common";
import Message from "./Message";
import Loader from "./Fields/Loader";
import ParaSelect from "./Fields/ParaSelect";
import ParaDate from "./Fields/ParaDate";
import ParaCheckbox from "./Fields/ParaCheckbox";
import FreeText from "./Fields/FreeText";
import PaMultiSelect from "./Fields/PaMultiSelect";
import { RptPara, SelectedRpt, SelectPara } from "../models/instantReport";
import { messageList } from "../utils/messageList";

const Wrapper = styled.div`
  padding: 10px 0 12px;
`;

interface Props {
  isParaListLoading: boolean;
  paraList: RptPara[];
  paraListErr: boolean;
  selectedRpt: SelectedRpt;
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
  getIntRptPara: (arg0: any) => void;
}

const Parameters: React.FC<Props> = ({
  isParaListLoading,
  paraList,
  paraListErr,
  selectedRpt,
  selectedPara,
  setSelectedPara,
  getIntRptPara,
}) => {
  return (
    <Wrapper>
      {isParaListLoading ? (
        <Col>
          <Loader />
        </Col>
      ) : paraListErr ? (
        <Col>
          <Message
            type="error"
            title="Error"
            desc={messageList.loadErr}
            isMainBtn={true}
            mainBtnTxt="Reload"
            handleMainBtnClick={() => getIntRptPara(selectedRpt.storeName)}
          />
        </Col>
      ) : paraList?.length === 0 ? (
        selectedRpt.storeName.length > 0 && <Col>No parameters</Col>
      ) : (
        paraList.map(
          ({ paraName, paraLabel, paraDefault, isParaMandatory, valueType, paraSize, paraKeys, valueList }, idx) =>
            valueType === "select" ? (
              <ParaSelect
                key={`${paraName}-${idx}`}
                paraName={paraName}
                label={paraLabel}
                valueType={valueType}
                defaultValue={paraDefault}
                isMandatory={isParaMandatory}
                // isMandatory={true}
                size={paraSize}
                optionListKeys={paraKeys}
                optionList={valueList}
                selectedPara={selectedPara}
                setSelectedPara={setSelectedPara}
              />
            ) : valueType === "date" ? (
              <ParaDate
                key={`${paraName}-${idx}`}
                name={paraName}
                label={paraLabel}
                valueType={valueType}
                isMandatory={isParaMandatory}
                // isMandatory={true}
                selectedPara={selectedPara}
                setSelectedPara={setSelectedPara}
              />
            ) : valueType === "checkbox" ? (
              <ParaCheckbox
                key={`${paraName}-${idx}`}
                name={paraName}
                label={paraLabel}
                valueType={valueType}
                defaultValue={paraDefault}
                isMandatory={isParaMandatory}
                selectedPara={selectedPara}
                setSelectedPara={setSelectedPara}
              />
            ) : valueType === "freetext" ? (
              <FreeText
                key={`${paraName}-${idx}`}
                name={paraName}
                label={paraLabel}
                valueType={valueType}
                defaultValue={paraDefault}
                isMandatory={isParaMandatory}
                selectedPara={selectedPara}
                setSelectedPara={setSelectedPara}
              />
            ) : valueType === "multiselect" ? (
              <PaMultiSelect
                key={`${paraName}-${idx}`}
                paraName={paraName}
                label={paraLabel}
                valueType={valueType}
                isMandatory={isParaMandatory}
                optionListKeys={paraKeys}
                optionList={valueList}
                selectedPara={selectedPara}
                setSelectedPara={setSelectedPara}
              />
            ) : null
        )
      )}
    </Wrapper>
  );
};

export default Parameters;
