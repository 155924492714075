import React, { useRef, useEffect, useState } from "react";
import { Button, Field, Input } from "@fluentui/react-components";
import { Dismiss12Regular, ChevronDown16Regular } from "@fluentui/react-icons";
import {
  Wrapper,
  FieldWrapper,
  DropdownWrapper,
  InputSection,
  InputWrapper,
  TagGroupWrapper,
  Tag,
  CustomizeInput,
  DropdownList,
  DropdownListItem,
} from "./common.styles";
import ModalTitle from "../Modals/ModalTitle";
import { optionItemMouseEnter, optionItemMouseLeave } from "../../helpers/mouseEvent";
import { SelectPara } from "../../models/instantReport";

interface Props {
  paraName: string;
  label: string;
  valueType: string;
  isMandatory: boolean;
  optionListKeys: string[] | [];
  optionList: any[];
  selectedPara: SelectPara;
  setSelectedPara: React.Dispatch<React.SetStateAction<SelectPara>>;
}

const PaMultiSelect: React.FC<Props> = ({
  paraName,
  label,
  valueType,
  isMandatory,
  optionListKeys,
  optionList,
  selectedPara,
  setSelectedPara,
}) => {
  const paraMainKey = optionListKeys[0];
  const targetSelectedParaObj = selectedPara[paraName];

  const inputRef = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const optionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [activeOptionIdx, setActiveOptionIdx] = useState<number>(-1);
  const [selectedOptionList, setSelectedOptionList] = useState<any[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredOptionList, setFilteredOptionList] = useState<any[]>([]);

  useEffect(() => {
    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        value: "",
        isMandatory: isMandatory,
        type: valueType,
        err: null,
      },
    }));

    // { USERACCESSTABLE: "", DESCRIPTION: null }
    let sortedOptionList = optionList
      .map((option) => {
        for (const key of optionListKeys) {
          if (typeof option[key] === "number") {
            option[key] = option[key].toString();
          }
          return option;
        }
      })
      .sort((a, b) => String(a[paraMainKey]).localeCompare(String(b[paraMainKey])));

    setFilteredOptionList(sortedOptionList);
  }, [optionList]);

  // Update filtered options when searchValue changes
  useEffect(() => {
    const filteredList = optionList?.filter((option) => {
      // { USERACCESSTABLE: "", DESCRIPTION: null }
      {
        for (const key of optionListKeys) {
          return option[key].toLowerCase().includes(searchValue.toLowerCase());
        }
      }
    });
    setFilteredOptionList(filteredList);
  }, [searchValue, optionList, optionListKeys]);

  // Scroll into view logic when activeOptionIdx changes
  useEffect(() => {
    if (activeOptionIdx >= 0 && optionRefs?.current[activeOptionIdx]) {
      optionRefs.current[activeOptionIdx]?.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeOptionIdx]);

  useEffect(() => {
    if (isOpen) {
      setSearchValue("");
      searchInputRef.current?.focus();
      document.getElementById(`paraSelect_${paraName}`)?.classList.add("active");
    }
  }, [isOpen, optionList]);

  const toggleDropdown = () => {
    setOpen((prev) => !prev);
    if (!isOpen) {
      inputRef.current?.focus();
      document.getElementById(`paraSelect_${paraName}`)?.classList.remove("active");
    }
  };

  const handleInputChange = (e: any) => {
    setSearchValue(e.target.value);
    setOpen(true);
    setActiveOptionIdx(0);
  };

  const handleSelect = (targetValue: any, targetIdx: number) => {
    const nextFilteredOptionList = filteredOptionList.filter(
      (option) => option[paraMainKey] != targetValue[paraMainKey]
    );
    setFilteredOptionList(nextFilteredOptionList);

    const nextSelectedOptionList = [...selectedOptionList, targetValue];
    setSelectedOptionList(nextSelectedOptionList);

    setSearchValue("");
    setActiveOptionIdx(targetIdx);

    const selectedValue = nextSelectedOptionList.map((option) => option[paraMainKey]);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        ...targetSelectedParaObj,
        value: selectedValue,
        err: null,
      },
    }));

    searchInputRef.current?.focus();
    document.getElementById(`paraSelect_${paraName}`)?.classList.add("active");
  };

  const handleClearClick = () => {
    setSelectedOptionList([]);
    setSearchValue("");
    setActiveOptionIdx(-1);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: { ...targetSelectedParaObj, value: [], err: null },
    }));
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setOpen(true);
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Enter":
        e.preventDefault();
        setOpen(true);
        break;
      case "Escape":
        e.preventDefault();
        handleClearClick();
        break;
      default:
        break;
    }
  };

  // Handle key navigation (Up, Down, Tab, Enter, Escape)
  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        break;
      case "ArrowUp":
        e.preventDefault();
        setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        break;
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          // Handle Shift + Tab (move backwards = ArrowUp)
          setActiveOptionIdx((prevIdx) => (prevIdx === 0 ? filteredOptionList.length - 1 : prevIdx - 1));
        } else {
          // Handle Tab (move forward = ArrowDown)
          setActiveOptionIdx((prevIdx) => (prevIdx + 1) % filteredOptionList.length);
        }
        break;
      case "Enter":
        e.preventDefault();
        if (activeOptionIdx >= 0 && filteredOptionList.length > 0) {
          handleSelect(filteredOptionList[activeOptionIdx], activeOptionIdx);
        }
        break;
      case "Escape":
        e.preventDefault();
        if (searchValue.length > 0) {
          handleClearClick();
        } else {
          setOpen(false);
          inputRef.current?.focus();
          document.getElementById(`paraSelect_${paraName}`)?.classList.remove("active");
        }
        break;
      default:
        break;
    }
  };

  const handleRemoveOptionClick = (targetTag: any) => {
    // Remove targetValue from selectedOptionList and add it back to filteredOptionList
    const nextSelectedOptionList = selectedOptionList.filter((option) => option[paraMainKey] != targetTag[paraMainKey]);
    setSelectedOptionList(nextSelectedOptionList);

    // Add targetValue back to filteredOptionList and sort or maintain order if needed
    const nextFilteredOptionList = [...filteredOptionList, targetTag].sort((a, b) =>
      a[paraMainKey].localeCompare(b[paraMainKey])
    );
    setFilteredOptionList(nextFilteredOptionList);

    const selectedValue = nextSelectedOptionList.map((option) => option[paraMainKey]);

    setSelectedPara((prev) => ({
      ...prev,
      [paraName]: {
        ...targetSelectedParaObj,
        value: selectedValue,
        err: null,
      },
    }));
  };

  return (
    <Wrapper>
      <FieldWrapper>
        <Field
          label={label}
          size="medium"
          onKeyDown={handleInputKeyDown}
          required={isMandatory}
          validationState={targetSelectedParaObj?.err ? "error" : "none"}
          validationMessage={targetSelectedParaObj?.err === "empty" ? "This field cannot be empty" : null}
        >
          <Input
            ref={inputRef}
            className="input-dropdown"
            size="medium"
            placeholder="..."
            value={Array.isArray(targetSelectedParaObj?.value) ? targetSelectedParaObj?.value.join(", ") : ""}
            onChange={() => {
              setOpen(true);
            }}
            onClick={toggleDropdown}
            contentAfter={<ChevronDown16Regular />}
          />
        </Field>
      </FieldWrapper>

      <DropdownWrapper className={isOpen ? "active" : ""} onKeyDown={handleKeyDown}>
        <ModalTitle title={label} action={() => setOpen(false)} />

        <InputSection>
          <TagGroupWrapper>
            {selectedOptionList.map((tag, index) => (
              <Tag key={`${tag[paraMainKey]}_${index}`}>
                <p>{tag[paraMainKey]}</p>
                <Button
                  appearance="transparent"
                  icon={<Dismiss12Regular />}
                  size="small"
                  onClick={() => handleRemoveOptionClick(tag)}
                />
              </Tag>
            ))}
          </TagGroupWrapper>
          <InputWrapper id={`paraSelect_${paraName}`}>
            <CustomizeInput ref={searchInputRef} placeholder="..." value={searchValue} onChange={handleInputChange} />
            <Button appearance="transparent" icon={<Dismiss12Regular />} size="small" onClick={handleClearClick} />
          </InputWrapper>
        </InputSection>

        <DropdownList role="listbox">
          {filteredOptionList.length > 0 &&
            filteredOptionList.map((valueItem, idx) => (
              <DropdownListItem
                key={`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`}
                id={`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`}
                ref={(el) => (optionRefs.current[idx] = el)}
                className={`dropdown-item ${idx === activeOptionIdx ? "hovered" : ""}`}
                tabIndex={0}
                role="option"
                onClick={() => handleSelect(valueItem, idx)}
                onMouseEnter={() => optionItemMouseEnter(`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`)}
                onMouseLeave={() => optionItemMouseLeave(`select_${paraName}_option-${valueItem[paraMainKey]}-${idx}`)}
              >
                {optionListKeys.map((paraKey, idx) => (
                  <p key={`option_${valueItem[paraMainKey]}_${valueItem[paraKey]}-${idx}`}>{valueItem[paraKey]}</p>
                ))}
              </DropdownListItem>
            ))}

          {filteredOptionList.length === 0 && (
            // <DropdownListItem>New value: {searchValue}</DropdownListItem>
            <DropdownListItem>No item found</DropdownListItem>
          )}
        </DropdownList>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default PaMultiSelect;
