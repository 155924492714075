export const modalList = {
  filterTableColumn: "Filter table column",
  lookup: "Look up",
  viewRef: "View referencing records",
  insertRow: "Insert row",
  editRow: "Edit row",
  copyRow: "Copy row",
  deleteRow: "Delete row",
};

export const modalTitle = {
  filterTableColumn: "Filter table column",
  lookup: "Look up",
  viewRef: "View referencing records",
  insertRow: "Insert row",
  editRow: "Edit row",
  copyRow: "Copy row",
  deleteRow: "Delete row",
};


export const modalTooltip = {
  filterTableColumn: "Filter table column",
  lookup: "Look up",
  viewRef: "View referencing records",
  insertRow: "Insert row",
  editRow: "Edit row",
  copyRow: "Copy row",
  deleteRow: "Delete row",
};
